import './App.css';
import { useEffect } from 'react';
import NavBar from './components/NavBar';
import SideBar from './components/SideBar';
import Home from './pages/Home';
import Map from './pages/Map';
import Login from './pages/Login';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import ContextProvider from './utils/ContextProvider';
import GlobalFuncs from './utils/GlobalFuncs';
import Admin from './pages/Admin';
import Profile from './pages/Profile';
import PassChange from './pages/PassChange';
import EModel from './pages/EModel';
import WModel from './pages/WModel';
import GModel from './pages/GModel';
import CoModel from './pages/CoModel';
import Role from './pages/Role';
import Client from './pages/Client';
import RecoverGet from './pages/RecoverGet';
import Building from './pages/Building';
import User from './pages/User';
import Operator from './pages/Operator';
import OpHours from './pages/OpHours';
import Distribution from './pages/Distribution';
import Reports from './pages/Reports';
import Report from './pages/Report';
import Device from './pages/Device';
import Actuator from './pages/Actuator';
import Circuit from './pages/Circuit';
import Conservation from './pages/Conservation';
import PublicWater from './pages/PublicWater';
import PublicEmissions from './pages/PublicEmissions';
import ConservationPortfolio from './pages/ConservationPortfolio';
import PublicGenerationHistory from './pages/PublicGenerationHistory';
import PublicGenerationTotal from './pages/PublicGenerationTotal';
import PublicCongratulations from './pages/PublicCongratulations';
import PublicElectricHistory from './pages/PublicElectricUsage';
import PublicUtilitiesUsage from './pages/PublicUtilitiesUsage';
import Maintenance from './pages/Maintenance';
import Alerts from './pages/Alerts';
import UnderMaintenance from './pages/UnderMaintenance';
import ActuatorControl from './pages/ActuatorControl';
import SolarTest from './pages/SolarTest';

function App() {

  useEffect(() => {
    document.title = 'Greensense';
  }, []);

  return (
    <BrowserRouter>
      <ContextProvider>
        <SideBar >
          <GlobalFuncs.ScrollToTop />
          <NavBar>
            <Routes>
              <Route path="/login" element={<Login />} />
              <Route path="/home" element={<Home />} />
              <Route path="/distribution" element={<Distribution />} />
              <Route path="/reports" element={<Reports />} />
              <Route path="/report" element={<Report />} />
              <Route path="/map" element={<Map />} />
              <Route path="/settings/*" element={<Admin />} />
              <Route path="/profile" element={<Profile />} />
              <Route path="/passchange-in" element={<PassChange />} />
              <Route path="/passchange" element={<PassChange />} />
              <Route path="/model/create/electricity" element={<EModel />} />
              <Route path="/model/create/water" element={<WModel />} />
              <Route path="/model/create/gas" element={<GModel />} />
              <Route path="/model/create/co" element={<CoModel />} />
              <Route path="/role/create" element={<Role />} />
              <Route path="/building/create" element={<Building />} />
              <Route path="/user/create" element={<User />} />
              <Route path="/operator/create" element={<Operator />} />
              <Route path="/client/create" element={<Client />} />
              <Route path="/actuator/create" element={<Actuator />} />
              <Route path="/actuator/control" element={<ActuatorControl />} />
              <Route path="/device/create" element={<Device />} />
              <Route path="/model/electricity" element={<EModel />} />
              <Route path="/model/water" element={<WModel />} />
              <Route path="/model/gas" element={<GModel />} />
              <Route path="/model/co" element={<CoModel />} />
              <Route path="/role" element={<Role />} />
              <Route path="/client" element={<Client />} />
              <Route path="/buildings" element={<Building />} />
              <Route path="/ophours" element={<OpHours />} />
              <Route path="/user" element={<User />} />
              <Route path="/operator" element={<Operator />} />
              <Route path="/recover" element={<RecoverGet />} />
              <Route path="/actuator" element={<Actuator />} />
              <Route path="/device" element={<Device />} />
              <Route path="/circuit" element={<Circuit />} />
              <Route path="/conservation/portfolio" element={<ConservationPortfolio />} />
              <Route path="/conservation" element={<Conservation />} />
              <Route path="/maintenance" element={<Maintenance />} />
              <Route path="/public/water/*" element={<PublicWater />} />
              <Route path="/public/emissions/*" element={<PublicEmissions />} />
              <Route path="/public/generation/history" element={<PublicGenerationHistory />} />
              <Route path="/public/generation/total/*" element={<PublicGenerationTotal />} />
              <Route path="/public/congratulations" element={<PublicCongratulations />} />  
              <Route path="/public/generation/history" element={<PublicGenerationHistory />} />                            
              <Route path="/public/electric/history" element={<PublicElectricHistory />} />    
              <Route path="/public/utilities/usage" element={<PublicUtilitiesUsage />} />
              <Route path="/alerts" element={<Alerts />} />
              <Route path="/eriksprivatepage" element={<SolarTest />} />
              <Route path="/unavailable" element={<UnderMaintenance />} />
              <Route path="*" element={<Map />} />
            </Routes>
          </NavBar>
        </SideBar>
      </ContextProvider>
    </BrowserRouter>);
}

export default App;
