import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { AppContext } from "../../utils/ContextProvider";
import API from "../../utils/API";
import Swal from "sweetalert2";
import Loader from '../../components/Loader';

const SolarTest = () => {

    const context = useContext(AppContext);
    const [loading, setLoading] = useState(false)
    const [buildings, setBuildings] = useState(false)
    const [params, setParams] = useState({})
    const [data, setData] = useState(null)


    useEffect(() => {
        setLoading(true)
        if (!context.getUserInfo) return
        const cancelToken = API.cancelToken();

        API.getBuildings(cancelToken).then(res => {
            setLoading(false)
            setBuildings(res.data)
            return
        }).catch(err => {
            setLoading(false)
            console.log(err)
            if (err.message === 'cancelling') return
            Swal.fire({
                title: 'Error.',
                text: 'Please try again later.',
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#46775A'
            });

        })

        return () => {
            API.cancel(cancelToken);
        }
    }, [context.getUserInfo])


    const handleSubmit = (e) => {
        e.preventDefault()
        setLoading(true)
        API.getSolar2(params.building_id, params.date, params.interval).then(res => {
            setLoading(false)
            setData(res.data)
        }).catch(e => {
            console.log(e)
            setLoading(false)
            if (e.message === 'cancelling') return
            Swal.fire({
                title: 'Error.',
                text: 'Please try again later.',
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#46775A'
            });
        })
    }

    return (
        <div className='card m-8 p-8 w-[95%] h-[80vh]'>
            <select name="building" className='mr-3' onChange={(e) => setParams(prev => ({ ...prev, building_id: e.target.value }))}>
                <option value="">Building</option>
                {buildings && buildings.map((e, ix) => (
                    <option value={e.building_id}>{e.building}</option>
                ))}
            </select>
            <input type="date" className='mr-3' onChange={(e) => setParams(prev => ({ ...prev, date: e.target.value }))} />
            <select name="interval" className='mr-3' onChange={(e) => setParams(prev => ({ ...prev, interval: e.target.value }))}>
                <option value="">Interval</option>
                <option value="1h">1 hour</option>
                <option value="15m">15 mins</option>
            </select>
            {loading ?
                <Loader />
                :
                params.building_id && params.date && params.interval &&
                <button className='p-[6px] bg-[#3E5B4A] rounded-md text-[#fff]' onClick={handleSubmit}>Submit</button>
            }
            {
                data &&
                <div className='mt-8 w-full overflow-auto h-[640px]'>
                    <pre className='rounded-lg w-full' style={{
                        backgroundColor: "#f4f4f4",
                        padding: "30px",
                        borderRadius: "5px",
                        whiteSpace: "pre-wrap",
                        wordWrap: "break-word",
                        fontFamily: "monospace"
                    }}>
                        {JSON.stringify(data, null, 4)}
                    </pre>
                </div>
            }
        </div>
    )
};
export default SolarTest;