import React from 'react';
import { useState, useEffect, useContext, useRef } from 'react';
import { AppContext } from "../../utils/ContextProvider";
import { Link, useNavigate } from 'react-router-dom';
import Loader from "../../components/Loader";
import Accordion from "../../components/Accordion";
import { GoogleMap, useLoadScript, Marker, InfoWindowF } from '@react-google-maps/api';
import './style.css'

const Map = () => {

  const alertDict = { 0: '#0538A0', 1: '#F9941E', 2: '#F75D5F' }
  const severityDict = { 0: 'Critical', 1: 'Caution', 2: 'Info' }
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_KEY
  })
  const [clients, setClients] = useState(null)
  const [selectedClient, setSelectedClients] = useState(null)
  const context = useContext(AppContext);
  const [selectedMarker, setSelectedMarker] = useState(null)
  const [showAlertDetails, setShowAlertDetails] = useState(false)
  const navigate = useNavigate()
  const mapRef = useRef(null)

  useEffect(() => {
    context.resetBuilding()
    if (!context.getUserInfo) return
    let buildings = context.getUserInfo.buildings
    let tempClients = {}
    if (!buildings) return
    for (let item of buildings) {
      tempClients[item.client_name] = tempClients[item.client_name] || []
      tempClients[item.client_name].push(item)
    }
    tempClients = Object.keys(tempClients).map(e => ({ title: e, content: tempClients[e] }))
    tempClients.sort(function (a, b) {
      var titleA = a.title.toUpperCase();
      var titleB = b.title.toUpperCase();
      if (titleA < titleB) {
        return -1;
      }
      if (titleA > titleB) {
        return 1;
      }
      return 0;
    });

    setClients(tempClients)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [context.getUserInfo])

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (showAlertDetails && !event.target.closest('.alerts-list')) {
        setShowAlertDetails(false);
      }
    };
    document.addEventListener('click', handleClickOutside);
    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, [showAlertDetails]);

  const handleMarkerHover = (marker) => {
    setSelectedMarker(marker);
  };

  // Function to handle marker mouse out event
  const handleMarkerMouseOut = () => {
    setSelectedMarker(null);
  };

  const handlePinClick = (id) => {
    navigate(`/home?building=${id}`)
  }

  const fitBounds = () => {
    if (mapRef.current) {
      const bounds = new window.google.maps.LatLngBounds();

      clients[selectedClient].content.forEach(e => {
        bounds.extend(new window.google.maps.LatLng(parseFloat(e.latitude), parseFloat(e.longitude)));
      });

      mapRef.current.state.map.fitBounds(bounds);

      // Calculate the new zoom level with a maximum of 10% zoom
      const maxZoom = 10; // Adjust this value as needed
      const currentZoom = mapRef.current.state.map.getZoom();
      const newZoom = Math.min(currentZoom, maxZoom);

      mapRef.current.state.map.setZoom(newZoom);
    }
  };



  useEffect(() => {
    if (!selectedClient) return
    fitBounds()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClient])


  return (
    <div className='border-solid m-auto w-full h-full tablet:p-6 relative map-page'>
      {
        isLoaded ?
          <GoogleMap
            ref={mapRef}
            zoom={selectedClient ? 10 : 5}
            center={clients && selectedClient !== null ? { lat: parseFloat(clients[selectedClient].content[0].latitude), lng: parseFloat(clients[selectedClient].content[0].longitude) } : { lat: 56.7681, lng: -98.8661 }}
            mapContainerClassName="map-container">
            {clients &&
              selectedClient !== null &&
              clients[selectedClient].content.map((e, index) => (
                <Marker
                  key={index + 'mappin'}
                  position={{ lat: parseFloat(e.latitude), lng: parseFloat(e.longitude) }}
                  onMouseOver={() => handleMarkerHover(e)}
                  onMouseOut={handleMarkerMouseOut}
                  onClick={() => handlePinClick(e.building_id)}
                  icon={{
                    path: 'M50 10 A40 40 0 0 1 50 90 A40 40 0 0 1 50 10 Z',
                    fillColor: `${alertDict[Math.max(...e.alerts.map(e=>e.severity))]}`,
                    fillOpacity: 1,
                    scale: 0.3,
                  }}
                />
              ))}
            {
              selectedMarker && (
                <InfoWindowF
                  position={{ lat: parseFloat(selectedMarker.latitude), lng: parseFloat(selectedMarker.longitude) }}
                  onCloseClick={handleMarkerMouseOut}
                  options={{ pixelOffset: new window.google.maps.Size(0, -40) }}
                >
                  <div>
                    <p>{selectedMarker.building}</p>
                  </div>
                </InfoWindowF>
              )}
          </GoogleMap>
          :
          <Loader />
      }
      {clients ?
        <div className='absolute transparent-background flex flex-col items-center justify-center laptop:top-[15%] tablet:top-[60%] mobile:top-[60%] laptop:bottom-[15%] tablet:bottom-[6%] mobile:bottom-[3%] laptop:right-[7%] tablet:right-[10%] mobile:right-[20%] laptop:w-[20%] tablet:w-10/12 mobile:w-9/12 '>
          <div className={`card buildings-card w-full p-4 transform transition-all duration-500 ${showAlertDetails ? 'rounded-t-lg' : 'rounded'}`} style={{ height: showAlertDetails ? "50%" : "100%" }} >
            <Accordion items={clients} onSelect={setSelectedClients} setShowAlertDetails={setShowAlertDetails} />
          </div>
          <div className='transparent-background w-full card alerts-list flex items-start justify-center transform transition-all duration-500' style={{ height: showAlertDetails ? '50%' : '0%', opacity: showAlertDetails ? '100' : '0' }}>
            <div className='card buildings-card w-full mobile:h-full px-4 py-6 rounded-b-lg shadow-inner'>
              <p>Active Alerts</p>
              <div className='mt-2' >
                {showAlertDetails &&
                  <div className='flex flex-nowrap items-center justify-between'>
                    <div className='flex flex-nowrap justify-start items-center w-1/2 text-[12px] text-[#B8BBB9] font-semibold'>
                      Alert Group
                    </div>
                    <div className='flex flex-nowrap justify-center items-center w-3/12 text-[12px] text-[#B8BBB9] font-semibold'>
                      Severity
                    </div>
                    <div className='flex flex-nowrap justify-center items-center w-3/12 text-[12px] text-[#B8BBB9] font-semibold'>
                      Count
                    </div>
                  </div>
                }
              </div>
              <div className='overflow-y-auto h-[75%]'>
                {showAlertDetails && showAlertDetails.map((e, ix) => (
                  <div className='card my-2'>
                    <Link to={`/alerts?building=${e.building_id}&group=${e.id}`}>
                      <div key={'buildingalerts' + ix} className={`flex flex-nowrap items-center justify-between py-3 cursor-pointer`} style={{ color: alertDict[e.severity] }}>
                        <div className='flex flex-nowrap justify-start items-center w-1/2 text-[12px] font-semibold'>
                          {e.name}
                        </div>
                        <div className='flex flex-nowrap justify-center items-center w-3/12 text-[12px] font-semibold'>
                          {severityDict[e.severity]}
                        </div>
                        <div className='flex flex-nowrap justify-center items-center w-3/12 text-[12px] font-semibold'>
                          {e.count}
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}               
              </div>
            </div>
          </div>
        </div>
        :
        <Loader />
      }
    </div>
  );
};

export default Map;