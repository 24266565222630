import React, { useEffect, useContext, useState } from "react";
import './style.css'
import { AppContext } from "../../utils/ContextProvider";
import { useLocation, Link } from 'react-router-dom';

export default function NavBar(props) {

    const context = useContext(AppContext)
    const [ddOpen, setDdOpen] = useState(false)
    const [currentLocation, setCurrentLocation] = useState()
    const [currentTab, setCurrentTab] = useState()
    const [isAdmin, setIsAdmin] = useState(false)
    const [adminNavOpen, setAdminNavOpen] = useState(false)
    const [hidden, setHidden] = useState(false)
    const [magnify, setMagnify] = useState(false)
    const location = useLocation();
    const noSidebar = ['map', 'public']
    const currentTabTitle = {
        costsfactors: "Costs & Factors",
        roles: "Roles",
        clients: "Clients",
        buildings: "Buildings",
        users: "Users",
        operators: "Operators",
        ophours: "Operating Hours",
        devices: "Devices",
        actuators: "Actuators",
    }

    useEffect(() => {
        if (!context.getUserInfo) return
        let access = ["access_utilities_list", "create_utilities",
            "update_utilities", "access_users_list", "create_users", "update_users",
            "access_roles_list", "create_roles", "update_roles", "access_clients_list",
            "create_clients", "update_clients", "access_buildings_and_operators_list",
            "create_buildings_and_operators", "update_buildings_and_operators", "actuator_settings"]
        if (!access.some(item => context.getUserInfo[item] === true)) {

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.getUserInfo])

    useEffect(() => {
        setCurrentLocation(location.pathname.split("/")[1])
        setCurrentTab(location.pathname.split("/")[2])
        setAdminNavOpen(false)
    }, [location.pathname])

    useEffect(() => {
        if (noSidebar.includes(currentLocation)) setHidden(true)
        else setHidden(false)
        if (currentLocation === 'settings') setIsAdmin(true)
        else setIsAdmin(false)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentLocation])

    const magnifyImage = (url) => {
        setMagnify(url)
    }

    return (
        <div className="flex flex-col flex-nowrap">
            {!hidden && magnify &&
                <div className="highest-z fixed w-screen h-screen flex flex-nowrap justify-center items-center" onClick={() => setMagnify(false)}>
                    <img onClick={() => setMagnify(false)} className='nav-magnified-image' src={magnify} alt="" />
                </div>
            }
            {
                !hidden &&
                <div className='fixed z-20 top-0 left-0 w-screen'>
                    <div className='flex flex-row flex-nowrap justify-between items-center text-center w-full bg-[#46775A] py-2.5 text-white relative'>
                        <div className="flex flex-row flex-nowrap justify-end items-center w-full">
                            <div className="flex flex-row flex-nowrap laptop:gap-14 tablet:gap-10 justify-between mobile:pr-4 tablet:pr-6 min-h-[44px] items-center">
                                {
                                    context.getMobile &&
                                    <img onClick={() => context.setSidebar(true)} className='w-5/12 ml-2 p-2 cursor-pointer' src={require('../../assets/images/logo_full.png')} alt="" />
                                }
                                {!context.getMobile && context.getBuildingInfo && <div className="flex flex-row items-center gap-2">
                                    <div className="justify-end">
                                        <img onClick={() => magnifyImage(context.getBuildingInfo.image ? context.getBuildingInfo.image : require('../../assets/images/placeholder-image.png'))} className='navbar-image-limiter rounded-[4px]' src={context.getBuildingInfo.image ? context.getBuildingInfo.image : require('../../assets/images/placeholder-image.png')} alt="" />
                                    </div>
                                    <div className="justify-start">
                                        <p className="font-bold laptop:text-sm tablet:text-xs leading-5 text-[#FFFFFF] truncate laptop:max-w-none tablet:max-w-[180px] text-start">
                                            {context.getBuildingInfo.building}
                                        </p>
                                        <div className="flex gap-0 laptop:w-content">
                                            {/* {GlobalSVG.pointMap('white')} */}
                                            <p className="font-normal text-[11px] leading-5 text-[#FFFFFF] truncate laptop:max-w-none tablet:max-w-[180px]" >
                                                {/* <p className="font-normal text-[11px] leading-5 text-[#FFFFFF] ml-2 truncate laptop:max-w-none tablet:max-w-[180px]" > */}
                                                {`${context.getBuildingInfo.address} ${context.getBuildingInfo.city} ${context.getBuildingInfo.province}`}
                                            </p>
                                        </div>
                                    </div>

                                </div>}
                                {!context.getMobile &&
                                    isAdmin &&
                                    <div className="flex flex-row items-center mobile:gap-6 tablet:gap-4 laptop:gap-8 relative">
                                        {
                                            context.getUserInfo && context.getUserInfo.access_utilities_list &&
                                            <Link to={'/settings/costsfactors/electricity'}>
                                                <p
                                                    className={`font-normal text-base leading-5 pb-1 font-bold laptop:text-base tablet:text-sm mobile:text-xs ${currentTab === 'costsfactors' ? 'active-tab' : 'inactive-tab'}`}>
                                                    Costs & Factors
                                                </p>
                                            </Link>
                                        }
                                        {
                                            context.getUserInfo && context.getUserInfo.access_roles_list &&
                                            <Link to={'/settings/roles'}>
                                                <p
                                                    className={`font-normal text-base leading-5 pb-1 font-bold laptop:text-base tablet:text-sm mobile:text-xs ${currentTab === 'roles' ? 'active-tab' : 'inactive-tab'}`}>
                                                    Roles
                                                </p>
                                            </Link>
                                        }
                                        {
                                            context.getUserInfo && context.getUserInfo.access_clients_list &&
                                            <Link to={'/settings/clients'}>
                                                <p
                                                    className={`font-normal text-base leading-5 pb-1 font-bold laptop:text-base tablet:text-sm mobile:text-xs ${currentTab === 'clients' ? 'active-tab' : 'inactive-tab'}`}>
                                                    Clients
                                                </p>
                                            </Link>
                                        }
                                        {
                                            context.getUserInfo && context.getUserInfo.access_buildings_and_operators_list &&
                                            <Link to={'/settings/buildings'}>
                                                <p
                                                    className={`font-normal text-base leading-5 pb-1 font-bold laptop:text-base tablet:text-sm mobile:text-xs ${currentTab === 'buildings' ? 'active-tab' : 'inactive-tab'}`}>
                                                    Buildings
                                                </p>
                                            </Link>
                                        }
                                        {
                                            context.getUserInfo && context.getUserInfo.access_users_list &&
                                            <Link to={'/settings/users'}>
                                                <p
                                                    className={`font-normal text-base leading-5 pb-1 font-bold laptop:text-base tablet:text-sm mobile:text-xs ${currentTab === 'users' ? 'active-tab' : 'inactive-tab'}`}>
                                                    Users
                                                </p>
                                            </Link>
                                        }
                                        {
                                            context.getUserInfo && context.getUserInfo.access_buildings_and_operators_list &&
                                            <Link to={'/settings/operators'}>
                                                <p
                                                    className={`font-normal text-base leading-5 pb-1 font-bold laptop:text-base tablet:text-sm mobile:text-xs ${currentTab === 'operators' ? 'active-tab' : 'inactive-tab'}`}>
                                                    Operators
                                                </p>
                                            </Link>
                                        }
                                        {
                                            context.getUserInfo && context.getUserInfo.access_buildings_and_operators_list &&
                                            <Link to={'/settings/ophours'}>
                                                <p
                                                    className={`font-normal text-base leading-5 pb-1 font-bold laptop:text-base tablet:text-sm mobile:text-xs ${currentTab === 'ophours' ? 'active-tab' : 'inactive-tab'}`}>
                                                    Operating Hours
                                                </p>
                                            </Link>
                                        }
                                        {
                                            context.getUserInfo && context.getUserInfo.access_buildings_and_operators_list &&
                                            <Link to={'/settings/devices?active=1'}>
                                                <p
                                                    className={`font-normal text-base leading-5 pb-1 font-bold laptop:text-base tablet:text-sm mobile:text-xs ${currentTab === 'devices' ? 'active-tab' : 'inactive-tab'}`}>
                                                    Devices
                                                </p>
                                            </Link>
                                        }
                                        {
                                            context.getUserInfo && context.getUserInfo.access_buildings_and_operators_list &&
                                            <Link to={'/settings/actuators'}>
                                                <p
                                                    className={`font-normal text-base leading-5 pb-1 font-bold laptop:text-base tablet:text-sm mobile:text-xs ${currentTab === 'actuators' ? 'active-tab' : 'inactive-tab'}`}>
                                                    Actuators
                                                </p>
                                            </Link>
                                        }
                                    </div>
                                }
                                {
                                    context.getMobile && isAdmin &&
                                    <div className="relative">
                                        <div onClick={() => setAdminNavOpen(!adminNavOpen)} className="cursor-pointer flex flex-nowrap justify-between items-center w-[140px] active-tab">
                                            <p className="font-normal text-base leading-5 pb-1 font-bold laptop:text-base tablet:text-sm mobile:text-xs height-fit">{currentTabTitle[currentTab]}</p>
                                            <svg onClick={() => setAdminNavOpen(!adminNavOpen)} transform={adminNavOpen ? "'rotate(180 25 25)'" : ""} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7 14L12 9L17 14H7Z" fill="white" />
                                            </svg>
                                        </div>
                                        {context.getMobile &&
                                            isAdmin &&

                                            <div className={`${adminNavOpen ? 'h-fit' : 'h-0'} transition-height duration-1000 ease-in-out absolute top-[100%] w-[140px] left-0 w-content background-white`}>
                                                {adminNavOpen && <div className={`dropdown-content card rounded p-4 h-content`}>
                                                    <Link to={'/settings/costsfactors/electricity'}>
                                                        <p
                                                            className={`text-black font-normal text-base py-2 font-bold mobile:text-xs input-border`}>
                                                            Costs & Factors
                                                        </p>
                                                    </Link>
                                                    <Link to={'/settings/roles'}>
                                                        <p
                                                            className={`text-black font-normal text-base py-2 font-bold mobile:text-xs input-border`}>
                                                            Roles
                                                        </p>
                                                    </Link>
                                                    <Link to={'/settings/clients'}>
                                                        <p
                                                            className={`text-black font-normal text-base py-2 font-bold mobile:text-xs input-border`}>
                                                            Clients
                                                        </p>
                                                    </Link>
                                                    <Link to={'/settings/buildings'}>
                                                        <p
                                                            className={`text-black font-normal text-base py-2 font-bold mobile:text-xs input-border`}>
                                                            Buildings
                                                        </p>
                                                    </Link>
                                                    <Link to={'/settings/users'}>
                                                        <p
                                                            className={`text-black font-normal text-base py-2 font-bold mobile:text-xs input-border`}>
                                                            Users
                                                        </p>
                                                    </Link>
                                                    <Link to={'/settings/operators'}>
                                                        <p
                                                            className={`text-black font-normal text-base py-2 font-bold mobile:text-xs input-border`}>
                                                            Operators
                                                        </p>
                                                    </Link>
                                                    <Link to={'/settings/ophours'}>
                                                        <p
                                                            className={`text-black font-normal text-base py-2 font-bold mobile:text-xs input-border`}>
                                                            Operating Hours
                                                        </p>
                                                    </Link>
                                                    <Link to={'/settings/devices'}>
                                                        <p
                                                            className={`text-black font-normal text-base py-2 font-bold mobile:text-xs input-border`}>
                                                            Devices
                                                        </p>
                                                    </Link>
                                                    <Link to={'/settings/actuators'}>
                                                        <p
                                                            className={`text-black font-normal text-base py-2 font-bold mobile:text-xs input-border`}>
                                                            Actuators
                                                        </p>
                                                    </Link>
                                                </div>}
                                            </div>
                                        }
                                    </div>
                                }
                                {!context.getMobile && context.getBuildingInfo && <div className="flex flex-row items-center gap-2 relative">
                                    <div className="">
                                        {context.getUserInfo && context.getUserInfo.role_id !== "1" && <img onClick={() => magnifyImage(context.getBuildingInfo.rep_image ? context.getBuildingInfo.rep_image : require('../../assets/images/placeholder-image.png'))} className='navbar-image-limiter rounded-[4px]' src={context.getBuildingInfo.rep_image ? context.getBuildingInfo.rep_image : require('../../assets/images/placeholder-image.png')} alt="" />}
                                    </div>
                                    <div className="" >
                                        {context.getUserInfo && context.getUserInfo.role_id !== "1" && <p className="font-bold laptop:text-sm tablet:text-xs leading-5 text-[#FFFFFF] cursor-pointer" onClick={() => setDdOpen(!ddOpen)}>Greenwave Contact</p>}
                                        <div className="flex gap-2">
                                            <p className="font-normal text-[11px] leading-5 text-[#FFFFFF] cursor-pointer truncate laptop:max-w-none tablet:max-w-[80px]" onClick={() => setDdOpen(!ddOpen)}>{context.getUserInfo && context.getUserInfo.role_id !== "1" ? context.getBuildingInfo.rep_name : "Building Operators"}</p>
                                            <svg onClick={() => setDdOpen(!ddOpen)} transform={ddOpen ? "'rotate(180 25 25)'" : ""} width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path d="M7 14L12 9L17 14H7Z" fill="white" />
                                            </svg>
                                            {ddOpen &&
                                                (
                                                    context.getUserInfo && context.getUserInfo.role_id === "1" ?
                                                        <div className="absolute top-[124%] left-0 w-full background-white">
                                                            {
                                                                context.getBuildingInfo.operators.map((e, idx) => (
                                                                    <div key={idx + "operators"} className={`dropdown-content card rounded p-4 h-content`}>
                                                                        <div className="flex flex-nowrap justify-start items-center mb-1">
                                                                            <img onClick={() => magnifyImage(e.op_image ? e.op_image : require('../../assets/images/placeholder-image.png'))} className='navbar-image-limiter w-5 mr-2 rounded-[4px]' src={e.op_image ? e.op_image : require('../../assets/images/placeholder-image.png')} alt="" />
                                                                            <p className="text-black truncate">{e.op_name}</p>
                                                                        </div>
                                                                        <p className="text-black laptop:text-sm tablet:text-sm text-left mb-1 font-bold">Op. Email:</p>
                                                                        <p className="text-black laptop:text-sm tablet:text-xs text-left mb-2">{e.op_email}</p>
                                                                        <p className="text-black laptop:text-sm tablet:text-sm text-left mb-1 font-bold">Op. Phone:</p>
                                                                        <p className="text-black laptop:text-sm tablet:text-xs text-left">{e.op_phone}</p>
                                                                    </div>
                                                                ))
                                                            }
                                                        </div>
                                                        :
                                                        <div className="absolute top-[124%] left-0 w-content background-white">
                                                            <div className={`dropdown-content card rounded p-4 h-content`}>
                                                                <p className="text-black laptop:text-base tablet:text-sm text-left mb-1 font-bold">Rep. Email:</p>
                                                                <p className="text-black laptop:text-sm tablet:text-xs text-left mb-2 ml-2">{context.getBuildingInfo.rep_email}</p>
                                                                <p className="text-black laptop:text-base tablet:text-sm text-left mb-1 font-bold">Rep. Phone:</p>
                                                                <p className="text-black laptop:text-sm tablet:text-xs text-left ml-2">{context.getBuildingInfo.rep_phone}</p>
                                                            </div>
                                                        </div>
                                                )
                                            }
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        </div>

                    </div>
                </div>
            }
            {
                !hidden &&
                <div className="navbar-height">
                    <p className="p-[34px] text-[white]">buffer</p>
                </div>
            }
            <div className={`bg-[#FAFAFA] ${hidden ? 'h-full' : ''}`}>
                {props.children}
            </div>
            {
                !hidden &&
                <div className="flex mobile:flex-col tablet:flex-row flex-nowrap justify-center items-center">
                    <img className='w-80 p-4 mr-5' src={require('../../assets/images/logo_full_brand.png')} alt="" />
                    <p className="text-[##5A5A5C] text-xs ml-5">@ 2023 Greenwave Innovations. All Rights Reserved.</p>
                </div>
            }
        </div >
    )
}
