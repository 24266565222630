import React, { useEffect, useContext, useState } from 'react';
import Loader from "../../components/Loader";
import './style.css'
import { AppContext } from '../../utils/ContextProvider';
import SlideToggle from '../SlideToggle';

const DeviceTab = (props) => {

    const context = useContext(AppContext)
    const [buildingOptions, setBuildingOptions] = useState([])

    useEffect(() => {
        if (!context.getUserInfo) return
        setBuildingOptions(context.getUserInfo.buildings.map(e => ({ building: e.building, building_id: e.building_id })))
    }, [context.getUserInfo])

    return (
        <div className='device'>
            <div className='min-h-[80vh]'>
                <div className='card tablet:p-8 mobile:p-3 min-h-[70vh] mb-6'>
                    <div className="input-border flex flex-wrap items-center justify-between text-3xl p-2 w-full">
                        <div className='items-center mb-6 laptop:w-1/2 tablet:w-full mobile:'>
                            <input
                                placeholder='Device ID'
                                className='text-3xl p-2 outline-none hide-input-background laptop:w-1/2 tablet:w-full mobile:w-full'
                                type="text"
                                name='device_id'
                                disabled={props.getPutPost === 'get'}
                                defaultValue={props.deviceData.device_id ? props.deviceData.device_id : ""}
                                onChange={props.updateDevice} />
                        </div>
                        <div className="flex flex-nowrap items-center justify-center mb-6">
                            <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color mt-2' htmlFor="active">Active</label>
                            <SlideToggle disabled={props.getPutPost === 'get'} setOptions={props.setDeviceData} options={props.deviceData} id={"active"} />
                        </div>
                    </div>
                    {
                        props.loading &&
                        <Loader />
                    }
                    <div className='flex flex-wrap'>
                        <div className='input-border flex flex-wrap tablet:w-4/12 mobile:w-full items-center pt-3 pb-3 mb-6'>
                            <div className='w-full flex flex-nowrap items-center'>
                                <label className='w-4/12 text-base input-label-color' htmlFor="location">Location</label>
                                <input
                                    placeholder='Location'
                                    className='p-1 w-full mr-2 text-base input-color outline-none hide-input-background p-2'
                                    type="text"
                                    name="location"
                                    defaultValue={props.deviceData.location ? props.deviceData.location : ""}
                                    disabled={props.getPutPost === 'get'}
                                    onChange={props.updateDevice} />
                                {
                                    props.error && props.error.location &&
                                    <p className='text-red text-xs'>{props.error.location}</p>

                                }
                            </div>
                        </div>
                        <div className='input-border flex flex-wrap tablet:w-5/12 mobile:w-full items-center pt-3 pb-3 mb-6'>
                            <div className='w-full flex flex-nowrap items-center'>
                                <label className='w-4/12 text-base input-label-color' htmlFor="location">Device Name</label>
                                <input
                                    placeholder='Device Name'
                                    className='p-1 w-full mr-2 text-base input-color outline-none hide-input-background p-2'
                                    type="text"
                                    name="device_name"
                                    defaultValue={props.deviceData.device_name ? props.deviceData.device_name : ""}
                                    disabled={props.getPutPost === 'get'}
                                    onChange={props.updateDevice} />
                                {
                                    props.error && props.error.device_name &&
                                    <p className='text-red text-xs'>{props.error.device_name}</p>

                                }
                            </div>
                        </div>
                        <div className='input-border laptop:w-3/12 tablet:w-3/12 mobile:w-full flex flex-wrap items-center pt-3 pb-3 mb-6'>
                            <label className='mobile:w-1/2 tablet:w-3/12 text-base input-label-color' htmlFor="building_id">Building</label>
                            <select
                                className='p-1 mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                name="building_id"
                                disabled={props.getPutPost === 'get'}
                                value={props.deviceData.building_id ? props.deviceData.building_id : ""}
                                onChange={props.updateDevice}>
                                <option value="">No Selection</option>
                                {buildingOptions.map((e, idx) => (
                                    <option key={idx + 'buldingoptions'} value={e.building_id}>{e.building}</option>

                                ))}
                            </select>
                            {
                                props.error && props.error.building_id &&
                                <p className='text-red text-xs'>{props.error.building_id}</p>

                            }
                        </div>
                    </div>
                    <div className='flex flex-wrap'>
                        <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                            <div className='w-full flex flex-wrap items-center'>
                                <label className='mobile:w-1/2 tablet:w-2/5 text-base input-label-color' htmlFor="monitored_distribution_panel">Monitored Distribution / Panel</label>
                                <input
                                    placeholder='Monitored Distribution / Panel'
                                    className='p-1 mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                    type="text"
                                    name="monitored_distribution_panel" defaultValue={props.deviceData.monitored_distribution_panel ? props.deviceData.monitored_distribution_panel : ""}
                                    disabled={props.getPutPost === 'get'}
                                    onChange={props.updateDevice} />
                                {
                                    props.error && props.error.monitored_distribution_panel &&
                                    <p className='text-red text-xs'>{props.error.monitored_distribution_panel}</p>

                                }
                            </div>
                        </div>
                        <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                            <div className='w-full flex flex-wrap items-center'>
                                <label className='mobile:w-1/2 tablet:w-3/12 text-base input-label-color' htmlFor="phase_voltage">Phase / Voltage</label>
                                <select
                                    className='p-1 mobile:w-1/2 tablet:w-8/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                    name="phase_voltage"
                                    value={props.deviceData.phase_voltage ? props.deviceData.phase_voltage : ""}
                                    disabled={props.getPutPost === 'get'}
                                    onChange={props.updateDevice}>
                                    <option value="">No Selection</option>
                                    <option value="1">1</option>
                                    <option value="2">2</option>
                                    <option value="3">3</option>
                                </select>
                                {
                                    props.error && props.error.phase_voltage &&
                                    <p className='text-red text-xs'>{props.error.phase_voltage}</p>

                                }
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-wrap'>
                        <div className='input-border flex flex-wrap laptop:w-1/3 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                            <div className='w-full flex flex-wrap items-center'>
                                <label className='mobile:w-1/2 tablet:w-4/12 text-base input-label-color' htmlFor="device_type">Device Model</label>
                                <select
                                    className='p-1 mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                    name="device_type"
                                    value={props.deviceData.device_type ? props.deviceData.device_type : ""}
                                    disabled={props.getPutPost === 'get'}
                                    onChange={props.updateDevice}>
                                    <option value="">No Selection</option>
                                    <option value="acurev">Acurev</option>
                                    <option value="egauge">EGauge</option>
                                    <option value="virtual">Virtual</option>
                                </select>
                                {
                                    props.error && props.error.device_type &&
                                    <p className='text-red text-xs'>{props.error.device_type}</p>

                                }
                            </div>
                        </div>
                        <div className='input-border flex flex-wrap laptop:w-1/3 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                            <div className='w-full flex flex-wrap items-center'>
                                <label className='mobile:w-1/2 tablet:w-5/12 text-base input-label-color' htmlFor="network_dhcp">Dynamic IP</label>
                                <input className='mr-3' disabled={props.getPutPost === 'get'} name="network_dhcp" type="checkbox" checked={props.deviceData.network_dhcp} onChange={props.updateDevice} />
                                {
                                    props.error && props.error.network_dhcp &&
                                    <p className='text-red text-xs'>{props.error.network_dhcp}</p>

                                }
                            </div>
                        </div>
                        <div className='input-border flex flex-wrap laptop:w-1/3 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                            <div className='w-full flex flex-wrap items-center'>
                                <label className='mobile:w-1/2 tablet:w-3/12 text-base input-label-color' htmlFor="ip_address">IP Address</label>
                                <input
                                    placeholder='IP Address'
                                    className='p-1 mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                    type="text"
                                    name="ip_address"
                                    defaultValue={props.deviceData.ip_address ? props.deviceData.ip_address : ""}
                                    disabled={props.getPutPost === 'get'}
                                    onChange={props.updateDevice} />
                                {
                                    props.error && props.error.ip_address &&
                                    <p className='text-red text-xs'>{props.error.ip_address}</p>

                                }
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-wrap'>
                        <div className='input-border flex flex-wrap w-full items-center pt-3 pb-3 mb-6'>
                            <div className='w-full flex flex-nowrap items-center'>
                                <label className='w-4/12 text-base input-label-color p-2' htmlFor="remote_access_URL">Remote Access Enabled and URL</label>
                                <input
                                    placeholder='URL'
                                    className='p-1 w-full mr-2 text-base input-color outline-none hide-input-background'
                                    type="text"
                                    name="remote_access_URL"
                                    defaultValue={props.deviceData.remote_access_URL ? props.deviceData.remote_access_URL : ""}
                                    disabled={props.getPutPost === 'get'}
                                    onChange={props.updateDevice} />
                                {
                                    props.error && props.error.remote_access_URL &&
                                    <p className='text-red text-xs'>{props.error.remote_access_URL}</p>

                                }
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-wrap'>
                        <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                            <div className='w-full flex flex-wrap items-center'>
                                <label className='mobile:w-1/2 tablet:w-1/5 text-base input-label-color' htmlFor="installed_by">Installed By</label>
                                <input
                                    placeholder='Installed By'
                                    className='p-1 mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                    type="text"
                                    name="installed_by"
                                    defaultValue={props.deviceData.installed_by ? props.deviceData.installed_by : ""}
                                    disabled={props.getPutPost === 'get'}
                                    onChange={props.updateDevice} />
                                {
                                    props.error && props.error.installed_by &&
                                    <p className='text-red text-xs'>{props.error.installed_by}</p>

                                }
                            </div>
                        </div>
                        <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                            <div className='w-full flex flex-wrap items-center'>
                                <label className='mobile:w-1/2 tablet:w-1/5 text-base input-label-color' htmlFor="installed_date">Install Date</label>
                                <input
                                    className='p-1 mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                    type="date"
                                    name="installed_date"
                                    defaultValue={props.deviceData.installed_date ? props.deviceData.installed_date.split("T")[0] : ''}
                                    disabled={props.getPutPost === 'get'}
                                    onChange={props.updateDevice} />
                                {
                                    props.error && props.error.installed_date &&
                                    <p className='text-red text-xs'>{props.error.installed_date}</p>

                                }
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-wrap w-full'>
                        <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                            <div className='w-full flex flex-wrap items-center'>
                                <label className='mobile:w-1/2 tablet:w-3/12 text-base input-label-color' htmlFor="meter_pwrd_by_breaker_no">Device Powered By</label>
                                <input
                                    placeholder='Device Powered By'
                                    className='p-1 mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                    type="number"
                                    max="99"
                                    name="meter_pwrd_by_breaker_no"
                                    defaultValue={props.deviceData.meter_pwrd_by_breaker_no ? props.deviceData.meter_pwrd_by_breaker_no : ""}
                                    disabled={props.getPutPost === 'get'}
                                    onChange={props.updateDevice} />
                                {
                                    props.error && props.error.meter_pwrd_by_breaker_no &&
                                    <p className='text-red text-xs'>{props.error.meter_pwrd_by_breaker_no}</p>

                                }
                            </div>
                        </div>
                        <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                            <div className='w-full flex flex-wrap items-center'>
                                <div className='w-full flex flex-wrap items-center'>
                                    <label className='mobile:w-1/2 tablet:w-3/12 text-base input-label-color' htmlFor="device_id">Serial Number</label>
                                    <input
                                        placeholder='Serial Number'
                                        className='p-1 mobile:w-1/2 tablet:w-8/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                        type="text"
                                        name="device_id"
                                        defaultValue={props.deviceData.device_id ? props.deviceData.device_id : ""}
                                        disabled={props.getPutPost === 'get'}
                                        onChange={props.updateDevice} />
                                    {
                                        props.error && props.error.device_id &&
                                        <p className='text-red text-xs'>{props.error.device_id}</p>

                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-wrap'>
                        <div className='input-border flex flex-wrap w-full items-center pt-3 pb-3 mb-6'>
                            <div className='w-full flex flex-nowrap items-center'>
                                <label className='w-2/12 text-base input-label-color' htmlFor="mac_address">Mac Address</label>
                                <input
                                    placeholder='Mac Address'
                                    className='p-1 w-full mr-2 text-base input-color outline-none hide-input-background p-2'
                                    type="text"
                                    name="mac_address" defaultValue={props.deviceData.mac_address ? props.deviceData.mac_address : ""}
                                    disabled={props.getPutPost === 'get'}
                                    onChange={props.updateDevice} />
                                {
                                    props.error && props.error.mac_address &&
                                    <p className='text-red text-xs'>{props.error.mac_address}</p>

                                }
                            </div>
                        </div>
                    </div>
                    <div className='flex flex-wrap'>
                        <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                            <div className='w-full flex flex-wrap items-center'>
                                <label className='mobile:w-1/2 tablet:w-3/12 text-base input-label-color' htmlFor="commissioned_by">Commissioned By</label>
                                <input
                                    placeholder='Commissioned By'
                                    className='p-1 mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                    type="text"
                                    name="commissioned_by"
                                    defaultValue={props.deviceData.commissioned_by ? props.deviceData.commissioned_by : ""}
                                    disabled={props.getPutPost === 'get'}
                                    onChange={props.updateDevice} />
                                {
                                    props.error && props.error.commissioned_by &&
                                    <p className='text-red text-xs'>{props.error.commissioned_by}</p>

                                }
                            </div>
                        </div>
                        <div className='input-border flex flex-wrap laptop:w-1/2 tablet:w-full mobile:w-full items-center pt-3 pb-3 mb-6'>
                            <div className='w-full flex flex-wrap items-center'>
                                <label className='mobile:w-1/2 tablet:w-3/12 text-base input-label-color' htmlFor="commissioned_date">Commissioned Date</label>
                                <input
                                    className='p-1 mobile:w-1/2 tablet:w-7/12 mr-2 text-base input-color p-2 outline-none hide-input-background'
                                    type="date"
                                    name="commissioned_date"
                                    disabled={props.getPutPost === 'get'}
                                    defaultValue={props.deviceData.commissioned_date ? props.deviceData.commissioned_date.split("T")[0] : ''}
                                    onChange={props.updateDevice} />
                                {
                                    props.error && props.error.commissioned_date &&
                                    <p className='text-red text-xs'>{props.error.commissioned_date}</p>

                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DeviceTab;