import LoaderWheel from "../LoaderWheel"
import { useContext, useEffect, useState } from "react"
import { AppContext } from '../../utils/ContextProvider'
import API from "../../utils/API"
import Swal from 'sweetalert2'
import GlobalSVG from "../../utils/GlobalSVG"
import { Link } from "react-router-dom"
import moment from 'moment'
import AlertWorkflowLine from "../AlertWorkflowLine"
import Tooltip from "../../components/ToolTip";
import AlertSubLogLine from "../AlertSubLogLine"

const AlertLogLine = (props) => {

    const context = useContext(AppContext)
    const [saving, setSaving] = useState(false)
    const [assigning, setAssigning] = useState(false)
    const [assignee, setAssignee] = useState()
    const [buildingUsers, setBuildingUsers] = useState()
    const [closing, setClosing] = useState(false)
    const [log, setLog] = useState(null)
    const [viewDetails, setViewDetails] = useState(false)
    const [viewSubLog, setViewSubLog] = useState(false)
    const [fetchingSubLog, setFetchingSubLog] = useState(false)
    const statusColor = { '0': '#0538A0', '1': '#F9941E', '2': '#F75D5F' }
    const severityDict = { '0': 'Info', '1': 'Caution', '2': 'Critical' }
    const [selectedList, setSelecteList] = useState([])

    useEffect(() => {
        if (!props.log) return
        let tempLog = JSON.parse(JSON.stringify(props.log))
        setLog(tempLog)
    }, [props.log])

    useEffect(() => {
        if (!context.getUserInfo || !context.getBuildingInfo) return
        const cancelToken = API.cancelToken();
        if (context.getUserInfo.manage_others_alert_subscription) {
            API.getBuildingUsers(cancelToken, context.getBuildingInfo.building_id).then(res => {
                setBuildingUsers(res.data)
                return
            }).catch(err => {
                console.log(err)
                if (err.message === 'cancelling') return
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });

            })
        }
        return () => {
            API.cancel(cancelToken);
        }
    }, [context.getUserInfo, context.getBuildingInfo])

    useEffect(() => {
        if (assignee) {
            handleAssign()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [assignee])

    const handleAssign = () => {
        Swal.fire({
            title: 'Assign Alert.',
            text: `Would you like to assign this alert to ${assignee}`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, assign it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                setSaving(true)
                API.createWorkflow({ alert_log_id: log.id, assigned_to: assignee }).then(async res => {
                    let tempGroups = JSON.parse(JSON.stringify(props.groups))
                    let updatedGroups = tempGroups.map(e => {
                        if (e.id === log.alert_group_id) {
                            let tempLog = e.logs.find(e => e.id === log.id);
                            if (tempLog) {
                                tempLog = { ...tempLog, workflow: [...tempLog.workflow, res.data] }
                            }
                            let finalLogs = e.logs.map(log => log.id === tempLog.id ? tempLog : log)
                            props.setGroup && props.setGroup({ ...e, logs: finalLogs })
                            return { ...e, logs: finalLogs };
                        }
                        return e
                    })
                    props.setGroups(updatedGroups)
                }).catch(e => {
                    console.log(e)
                    Swal.fire({
                        title: 'Oops!',
                        text: 'An error occurred, please try again later.',
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    })
                }).finally(f => {
                    setSaving(false)
                    setAssignee(null)
                    setAssigning(false)
                })
            } else {
                setSaving(false)
                setAssignee(null)
                setAssigning(false)
            }
        })
    }

    const handleClose = () => {
        Swal.fire({
            title: 'Close Alert.',
            text: `Are you sure you want to close this alert?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, close it!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                setSaving(true)
                API.updateLog({}, log.id).then(res => {
                    let tempGroups = JSON.parse(JSON.stringify(props.groups))
                    let updatedGroups = tempGroups.map(e => {
                        if (e.id === log.alert_group_id) {
                            let tempLog = e.logs.find(e => e.id === log.id);
                            if (tempLog) {
                                tempLog = { ...tempLog, ...res.data }
                            }
                            let finalLogs = e.logs.map(log => log.id === tempLog.id ? tempLog : log)
                            props.setGroup && props.setGroup({ ...e, logs: finalLogs })
                            return { ...e, logs: finalLogs };
                        }
                        return e
                    })
                    props.setGroups(updatedGroups)
                }).catch(e => {
                    console.log(e)
                    Swal.fire({
                        title: 'Oops!',
                        text: 'An error occurred, please try again later.',
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    })
                }).finally(f => {
                    setSaving(false)
                    setClosing(false)
                })

            } else {
                setSaving(false)
                setClosing(false)
            }
        })
    }

    const fetchSubLog = () => {
        if (viewSubLog) setViewSubLog(false)
        else {
            setFetchingSubLog(true)
            API.getSubLogs(log.id).then(res => {
                setViewSubLog(res.data)
                return
            }).catch(err => {
                console.log(err)
                if (err.message === 'cancelling') return
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });

            }).finally(() => {
                setFetchingSubLog(false)
            })
        }
    }

    const handleSelect = (e) => {
        if (e.target.name === 'all' && e.target.checked) setSelecteList(viewSubLog.map(e => e.id))
        else if (e.target.name === 'all' && !e.target.checked) setSelecteList([])
        else if (e.target.checked) setSelecteList(l => [...l, parseInt(e.target.name)])
    }

    const handleCloseBulk = (value) => {
        Swal.fire({
            title: 'Close Alert.',
            text: `Are you sure you want to bulk-close the selected sub-log as ${value ? "False" : "Correct"} alerts?`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, close them!',
            cancelButtonText: 'No, cancel!',
            reverseButtons: true
        }).then((result) => {
            if (result.isConfirmed) {
                setSaving(true)
                API.updateSubLogBulk({ false_alert: value, list: selectedList }).then(res => {
                    const updatedLog = Object.fromEntries(res.data.updated_logs.map(item => [item.id, item]))
                    setViewSubLog(l =>
                        l.map(s =>
                            updatedLog[s.id]
                                ? { ...s, closed_at: updatedLog[s.id].closed_at, closed_by: updatedLog[s.id].closed_by }
                                : s
                        )
                    );
                }).catch(e => {
                    console.log(e)
                    Swal.fire({
                        title: 'Oops!',
                        text: 'An error occurred, please try again later.',
                        icon: 'warning',
                        confirmButtonText: 'Ok',
                        confirmButtonColor: '#46775A',
                    })
                }).finally(f => {
                    setSaving(false)
                    setClosing(false)
                })

            } else {
                setSaving(false)
                setClosing(false)
            }
        })
    }

    return (
        <div>
            {log &&
                <div style={{ color: log.closed_at ? "#B8BBB9" : statusColor[log.severity] }} className='flex flex-nowrap justify-between items-center p-4 my-2 w-full bg-[#ededed] rounded-md relative'>
                    {
                        props.byCircuit &&
                        <div className="flex flex-nowrap justify-start items-center w-[100px]">
                            <p className={`text-[12px] overflow-hidden overflow-ellipsis whitespace-nowrap w-[120px]`}>
                                {severityDict[log.severity]}
                            </p>
                        </div>
                    }
                    {
                        props.byCircuit &&
                        <div className="flex flex-nowrap justify-start items-center w-[120px]">
                            <p className={`text-[12px] overflow-hidden overflow-ellipsis whitespace-nowrap w-[120px]`}>
                                {log.type_name}
                            </p>
                        </div>
                    }
                    {
                        props.byCircuit && context && context.getBuildingInfo &&
                        <Link className="text-left" to={`/alerts?building=${context.getBuildingInfo.building_id}&group=${log.alert_group_id}`} target="_blank">
                            <div className="flex flex-nowrap justify-start items-center w-[120px]">
                                <p className={`text-[12px] overflow-hidden overflow-ellipsis whitespace-nowrap w-[120px] underline`}>
                                    {log.name}
                                </p>
                            </div>
                        </Link>
                    }
                    {!props.byCircuit && props.equipmentList &&
                        log.type_name === "Multi Channel Correlation" ?
                        <div className="w-[220px] text-[12px]">N/A</div>
                        :
                        (log.channel_type && log.channel_type !== 3 && log.point_name ?
                            <Link className="text-left" to={`/circuit?device=${props.equipmentList[log.channel_type][log.channel_id].device_id}&circuit=${encodeURIComponent(log.point_name)}&tab=3`} target="_blank">
                                <div className="flex flex-nowrap justify-start items-center w-[220px]">
                                    <p className={`text-[12px] overflow-hidden overflow-ellipsis whitespace-nowrap underline w-[135px]`}>
                                        {log.point_name}
                                    </p>
                                    {GlobalSVG.edit2()}
                                </div>
                            </Link>
                            :
                            <div className="flex flex-nowrap justify-start items-center w-[220px]">
                                <p className={`text-[12px] overflow-hidden overflow-ellipsis whitespace-nowrap w-[135px]`}>
                                    {log.point_name}
                                </p>
                            </div>)
                    }
                    {context.getBuildingInfo && log.first_timestamp && log.channel_id && (log.channel_type && log.channel_type !== 3 && log.point_name) ?
                        <Link to={`/distribution?building=${context.getBuildingInfo.building_id}&start=${moment(log.first_timestamp).format('YYYY-MM-DD')}&end=${moment(log.first_timestamp).format('YYYY-MM-DD')}&channel=${encodeURIComponent(log.point_name)}&type=${log.channel_type}`} target="_blank">
                            <div className={`underline w-[120px] text-[12px] ${log.first_timestamp ? "" : "pl-[25px]"}`}>{log.first_timestamp ? moment(log.first_timestamp).format('MMM D, YYYY h:mma') : " - "}</div>
                        </Link>
                        :
                        <div className={`w-[120px] text-[12px] ${log.first_timestamp ? "" : "pl-[25px]"}`}>{log.first_timestamp ? moment(log.first_timestamp).format('MMM D, YYYY h:mma') : " - "}</div>
                    }
                    {context.getBuildingInfo && log.last_timestamp && log.channel_id && (log.channel_type && log.channel_type !== 3 && log.point_name) ?
                        <Link to={`/distribution?building=${context.getBuildingInfo.building_id}&start=${moment(log.last_timestamp).format('YYYY-MM-DD')}&end=${moment(log.last_timestamp).format('YYYY-MM-DD')}&channel=${encodeURIComponent(log.point_name)}&type=${log.channel_type}`} target="_blank">
                            <div className={`underline w-[120px] text-[12px] ${log.last_timestamp ? "" : "pl-[25px]"}`}>{log.last_timestamp ? moment(log.last_timestamp).format('MMM D, YYYY h:mma') : " - "}</div>
                        </Link>
                        :
                        <div className={`w-[120px] text-[12px] ${log.last_timestamp ? "" : "pl-[25px]"}`}>{log.last_timestamp ? moment(log.last_timestamp).format('MMM D, YYYY h:mma') : " - "}</div>
                    }
                    <div className={`w-[120px] text-[12px]`}>{log.instance_count}</div>
                    <div className={`w-[100px] text-[12px] ${log.lastAssignedTo ? "" : "pl-[25px]"}`}>{log.lastAssignedTo ? log.lastAssignedTo : " - "}</div>
                    <div className={`w-[100px] text-[12px] ${log.closed_by && log.closed_by.trim() !== '' ? "" : "pl-[25px]"}`}>{log.closed_by && log.closed_by.trim() !== '' ? log.closed_by : " - "}</div>
                    <div className={`w-[120px] text-[12px] ${log.closed_at ? "" : "pl-[25px]"}`}>{log.closed_at ? moment(log.closed_at).format('MMM D, YYYY h:mma') : " - "}</div>
                    <div className="">
                        <div className="flex flex-nowrap justify-end items-center">
                            <div className='cursor-pointer w-[21px] mr-[4px]' >
                                <div onClick={() => setViewDetails(!viewDetails)}>{GlobalSVG.info('black')}</div>
                            </div>

                            <div className='cursor-pointer w-[25px] relative' onClick={() => setAssigning(!assigning)}>
                                {context.getUserInfo && context.getUserInfo.manage_alert_logs && GlobalSVG.assign('black')}
                                {
                                    assigning &&
                                    < div className={`absolute top-[120%] right-[30px] w-[200px] h-[180px] overflow-auto flex flex-col p-1 whitespace-nowrap shadow-md border-[#C1C1C1] z-10 bg-[#FFF]`}>
                                        {buildingUsers && buildingUsers.map((e, idx) => (
                                            <div key={idx + "assignees"} className="flex flex-row mx-2 py-1 border-b border-[#B8BBB9] text-[black]" onClick={() => setAssignee(e.username)} >
                                                {e.name}
                                            </div>
                                        ))
                                        }
                                    </div>
                                }
                            </div>
                            {
                                saving && assigning &&
                                <LoaderWheel />
                            }
                            {
                                !assigning &&
                                (!saving ?
                                    <div onClick={() => setClosing(!closing)} className="relative cursor-pointer">
                                        {GlobalSVG.check2()}
                                    </div>
                                    :
                                    <LoaderWheel />
                                )
                            }
                            <div className="relative">
                                {closing &&
                                    <div className={`rounded-md absolute top-[-20px] right-[24px] overflow-auto flex flex-col p-1 whitespace-nowrap shadow-md border-[#C1C1C1] z-10 bg-[#FFF]`}>
                                        <div className="flex flex-nowrap rounded-md">
                                            <div className="flex flex-nowrap justify-center items-center p-2 text-[black] font-semibold text-[11px] cursor-pointer" onClick={() => handleClose(true)} >
                                                {GlobalSVG.false()}
                                                <div className="ml-2 mt-1">
                                                    False Alert
                                                </div>
                                            </div>
                                            <div className="flex flex-row p-2 border-l border-[#B8BBB9] text-[black] font-semibold text-[11px] cursor-pointer" onClick={() => handleClose(false)} >
                                                {GlobalSVG.check2()}
                                                <div className="ml-2 mt-1">
                                                    Complete
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            }
            {viewDetails &&
                <div className="w-full flex flex-nowrap justify-end cursor-pointer px-[20px] py-[10px]">
                    <div onClick={() => setViewDetails(false)} className="mr-[10px]">
                        {GlobalSVG.close('black')}
                    </div>
                </div>
            }
            {
                viewDetails && log.workflow.length !== 0 &&
                <div className="w-full flex flex-nowrap justify-start cursor-pointer px-[20px] pb-[10px]">
                    <p>Log Workflow</p>
                </div>
            }

            {log && viewDetails && log.workflow &&
                <div className="w-full px-[20px] pb-[10px]">
                    {log.workflow.map((w, idx) => (
                        <AlertWorkflowLine workflow={w} log={log} groups={props.groups} group={props.group} setGroups={props.setGroups} setGroup={props.setGroup} />
                    ))}
                </div>
            }
            {
                viewDetails &&
                <div className="w-full flex flex-nowrap justify-between items-center cursor-pointer px-[20px]">
                    <div className="w-1/2 flex flex-nowrap justify-start items-center cursor-pointer">
                        <p>Sub-Log</p>
                        <Tooltip
                            text="The sub-log serves as a comprehensive record detailing every instance in which the alert has been triggered. It includes detailed information such as timestamps, threshold, and any associated metadata."
                            right="0"
                            left="400%"
                            top="0"
                            width="300px"
                            height="90px"
                            textAlign="left"
                            show={true}>
                            <div className="ml-2">
                                {GlobalSVG.info('black', '15', '15')}
                            </div>
                        </Tooltip>

                    </div>
                    {
                        fetchingSubLog ?
                            <LoaderWheel />
                            :
                            <p className="text-[10px]" onClick={fetchSubLog}>VIEW</p>
                    }
                </div>
            }
            {viewDetails && viewSubLog &&
                <div className="fixed w-screen h-screen top-0 left-0 modal-background flex flex-nowrap justify-center items-center alert-detail-modal">
                    <div className='card w-[80vw] h-[75vh] m-2 p-[40px] pt-[0] background-white rounded-md overflow-auto' onClick={(e) => { e.stopPropagation() }}>
                        <div className='min-w-[920px]'>
                            <div className="flex flex-nowrap justify-end items-center pt-[20px] w-full">
                                <div className='cursor-pointer ml-3' onClick={() => setViewSubLog(false)}>{GlobalSVG.closeXBig('black')}</div>
                            </div>
                            {
                                viewDetails && viewSubLog && context.getUserInfo.manage_alert_sub_logs &&
                                <div className="flex flex-nowrap items-center justify-between mx-[20px] mt-[10px] p-[10px] bg-[#F3F3F3] rounded-md">
                                    <p className="text-[12px]">Bulk Update</p>
                                    <div className="flex flex-nowrap items-center justify-end">
                                        {
                                            !saving ?
                                                <div onClick={() => setClosing(!closing)} className="relative cursor-pointer">
                                                    {GlobalSVG.check2()}
                                                    {closing &&
                                                        <div className={`rounded-md absolute top-[-20px] right-[24px] overflow-auto flex flex-col p-1 whitespace-nowrap shadow-md border-[#C1C1C1] z-10 bg-[#FFF]`}>
                                                            <div className="flex flex-nowrap rounded-md">
                                                                <div className="flex flex-nowrap justify-center items-center p-2 text-[black] font-semibold text-[11px] cursor-pointer" onClick={() => handleCloseBulk(true)} >
                                                                    {GlobalSVG.false()}
                                                                    <div className="ml-2 mt-1">
                                                                        False Alert
                                                                    </div>
                                                                </div>
                                                                <div className="flex flex-row p-2 border-l border-[#B8BBB9] text-[black] font-semibold text-[11px] cursor-pointer" onClick={() => handleCloseBulk(false)} >
                                                                    {GlobalSVG.check2()}
                                                                    <div className="ml-2 mt-1">
                                                                        Complete
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>}
                                                </div>
                                                :
                                                <LoaderWheel />
                                        }
                                        <input className="ml-[10px]" type="checkbox" name="all" id="" onChange={handleSelect} />
                                    </div>
                                </div>
                            }
                            <div className="px-[20px]">
                                {viewSubLog.map((e, idx) => (
                                    <AlertSubLogLine
                                        key={idx + "sublog"}
                                        handleSelect={handleSelect}
                                        byCircuit={props.byCircuit}
                                        selectedList={selectedList}
                                        log={log} sublog={e}
                                        equipmentList={props.equipmentList}
                                        group={props.group}
                                        setGroup={props.setGroup}
                                        groups={props.groups}
                                        setGroups={props.setGroups} />
                                ))}
                            </div>
                        </div>

                    </div>
                </div>}
        </div>
    )
}
export default AlertLogLine