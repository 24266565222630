import React from 'react';
import { useState, useEffect, useContext } from 'react';
import { AppContext } from "../../utils/ContextProvider";
import Loader from "../../components/Loader";
import { useLocation, Link, useNavigate } from 'react-router-dom';
import './style.css'
import API from '../../utils/API';
import Swal from 'sweetalert2';
import GlobalSVG from '../../utils/GlobalSVG';
import SlideToggle from '../../components/SlideToggle';

const Role = () => {

    const location = useLocation();
    const [role, setRole] = useState(null)
    const [getPutPost, setGetPutPost] = useState()
    const [roleData, setRoleData] = useState({
        role: "",
        access_buildings_and_operators_list: false,
        create_buildings_and_operators: false,
        update_buildings_and_operators: false,
        access_clients_list: false,
        create_clients: false,
        update_clients: false,
        access_roles_list: false,
        create_roles: false,
        update_roles: false,
        access_users_list: false,
        create_users: false,
        update_users: false,
        access_utilities_list: false,
        create_utilities: false,
        update_utilities: false,
        see_alerts: false,
        delete_alerts: false,
    })
    const [error, setError] = useState(null)
    const [loading, setLoading] = useState(false)
    const context = useContext(AppContext)
    const navigate = useNavigate();
    const queryParams = new URLSearchParams(location.search);

    useEffect(() => {
        if (!context.getUserInfo) return
        let access = ["access_roles_list", "create_roles", "update_roles"]
        if (!access.some(item => context.getUserInfo[item] === true)) {
            navigate('/settings/clients')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.getUserInfo])

    useEffect(() => {
        if (getPutPost === 'put') return
        if (location.pathname === '/role/create') {
            setGetPutPost('post')
        } else if (queryParams.size !== 0) {
            setRole(queryParams.get('role'))
            setGetPutPost('get')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams, location])

    useEffect(() => {
        const cancelToken = API.cancelToken();
        if (getPutPost === 'get' && role !== null) {
            setLoading(true)
            
            API.getRole(cancelToken, role).then(res => {
                setRoleData(res.data)
                return
            }).catch(err => {
                console.log(err)
                if (err.message === 'cancelling') return
                let message = 'Please try again later.'
                if (err.response && err.response.data && err.response.data.includes('error')) {
                    message = err.response.data.error
                }
                Swal.fire({
                    title: 'Error.',
                    text: message,
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });

            }).finally(f => {
                setLoading(false)
            })
        }
        return () => {
            API.cancel(cancelToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getPutPost, role])

    const updateRole = (e) => {
        const { name, value } = e.target;
        setRoleData(prevValue => {
            return {
                ...prevValue,
                [name]: value.trim()
            }
        })
    }

    const postRole = async (e) => {
        if (roleData.role === "") {
            setError({ role: "Missing Value" })
        } else {
            let payload = JSON.parse(JSON.stringify(roleData))
            delete payload['created_at'];
            delete payload['updated_at'];
            delete payload['role_id'];
            for (let key in payload) {
                if (payload[key] === null || String(payload[key]).trim() === "") {
                    delete payload[key];
                }
            }
            setLoading(true)
            const cancelToken = API.cancelToken();
            
            let request = null
            try {
                if (getPutPost === 'post') {
                    request = await API.postRole(cancelToken, payload)
                    navigate(`/role?role=${request.data.role_id}`)
                } else {
                    request = await API.putRole(cancelToken, role, payload)
                    setGetPutPost('get')
                    setError(null)
                }
            } catch (err) {
                console.log(err)
                if (err.message === 'cancelling') return
                if (err.response && err.response.data) {
                    try {
                        if (err.response.data.includes('error')) {
                            Swal.fire({
                                title: 'Error.',
                                text: err.response.data.error,
                                icon: 'warning',
                                confirmButtonText: 'Ok',
                                confirmButtonColor: '#46775A',
                            });
                        } else {
                            setError(err.response.data)
                        }
                    } catch (e) {
                        Swal.fire({
                            title: 'Error.',
                            text: err.response.data,
                            icon: 'warning',
                            confirmButtonText: 'Ok',
                            confirmButtonColor: '#46775A',
                        });
                    }
                }
            } finally {
                setLoading(false)
            }
        }
    }


    return (
        <div className='tablet:p-8 mobile:p-3 min-h-[80vh]'>
            <div className='flex flex-nowrap items-center gap-4 tablet:mb-8 mobile:mb-3 text-base'>
                <Link to="/settings/roles">
                    Roles
                </Link>
                {
                    GlobalSVG.rightArrow()
                }
                <p className='font-medium'>
                    {
                        ['get', 'put'].includes(getPutPost) ? roleData.role : "Add Role"

                    }
                </p>
            </div>
            <div className='card tablet:p-8 mobile:p-3 min-h-[70vh]'>
                <div className='input-border pb-8 items-center mb-6'>
                    <input
                        placeholder='Role Name'
                        className='text-3xl p-2 outline-none hide-input-background'
                        type="text"
                        name='role'
                        disabled={getPutPost === 'get'}
                        defaultValue={roleData.role ? roleData.role : ""}
                        onChange={updateRole} />
                    {
                        error && error.role &&
                        <p className='text-red text-xs p-2'>{error.role}</p>

                    }
                </div>
                {
                    loading &&
                    <Loader />
                }
                <div className='laptop:w-1/2 tablet:w-full mobile:full'>
                    <SlideToggle disabled={getPutPost === 'get'} setOptions={setRoleData} options={roleData} id="access_buildings_and_operators_list" name="Access Buildings and Operators List" />
                    <SlideToggle disabled={getPutPost === 'get'} setOptions={setRoleData} options={roleData} id="create_buildings_and_operators" name="Create Buildings and Operators" />
                    <SlideToggle disabled={getPutPost === 'get'} setOptions={setRoleData} options={roleData} id="update_buildings_and_operators" name="Update Buildings and Operators" />
                    <SlideToggle disabled={getPutPost === 'get'} setOptions={setRoleData} options={roleData} id="access_clients_list" name="Access Client List" />
                    <SlideToggle disabled={getPutPost === 'get'} setOptions={setRoleData} options={roleData} id="create_clients" name="Create Client" />
                    <SlideToggle disabled={getPutPost === 'get'} setOptions={setRoleData} options={roleData} id="update_clients" name="Update Client" />
                    <SlideToggle disabled={getPutPost === 'get'} setOptions={setRoleData} options={roleData} id="access_roles_list" name="Access Roles List" />
                    <SlideToggle disabled={getPutPost === 'get'} setOptions={setRoleData} options={roleData} id="create_roles" name="Create Roles" />
                    <SlideToggle disabled={getPutPost === 'get'} setOptions={setRoleData} options={roleData} id="update_roles" name="Update Roles" />
                    <SlideToggle disabled={getPutPost === 'get'} setOptions={setRoleData} options={roleData} id="access_users_list" name="Access Users List" />
                    <SlideToggle disabled={getPutPost === 'get'} setOptions={setRoleData} options={roleData} id="create_users" name="Create Users" />
                    <SlideToggle disabled={getPutPost === 'get'} setOptions={setRoleData} options={roleData} id="update_users" name="Update Users" />
                    <SlideToggle disabled={getPutPost === 'get'} setOptions={setRoleData} options={roleData} id="access_utilities_list" name="Access Factors List" />
                    <SlideToggle disabled={getPutPost === 'get'} setOptions={setRoleData} options={roleData} id="create_utilities" name="Create Factors" />
                    <SlideToggle disabled={getPutPost === 'get'} setOptions={setRoleData} options={roleData} id="update_utilities" name="Update Factors" />
                    <SlideToggle disabled={getPutPost === 'get'} setOptions={setRoleData} options={roleData} id="view_alerts" name="View Alerts Screen" />
                    <SlideToggle disabled={getPutPost === 'get'} setOptions={setRoleData} options={roleData} id="manage_my_alert_subscription" name="Manage Alerts Subscription (Personal)" />
                    <SlideToggle disabled={getPutPost === 'get'} setOptions={setRoleData} options={roleData} id="manage_alert_logs" name="Manage Alert Logs" />
                    <SlideToggle disabled={getPutPost === 'get'} setOptions={setRoleData} options={roleData} id="manage_alert_sub_logs" name="Manage Alert Sub-Logs" />
                    <SlideToggle disabled={getPutPost === 'get'} setOptions={setRoleData} options={roleData} id="manage_others_alert_subscription" name="Manage Alerts Subscription (General)" />
                    <SlideToggle disabled={getPutPost === 'get'} setOptions={setRoleData} options={roleData} id="manage_alert_settings" name="Manage Alerts Settings" />
                    <SlideToggle disabled={getPutPost === 'get'} setOptions={setRoleData} options={roleData} id="manage_alert_settings_real_time" name="Manage Alerts Settings (Real Time)" />
                    <SlideToggle disabled={getPutPost === 'get'} setOptions={setRoleData} options={roleData} id="actuator_settings" name="Manage Actuator Settings" />
                    <SlideToggle disabled={getPutPost === 'get'} setOptions={setRoleData} options={roleData} id="actuator_control" name="Control Actuators" />
                </div>
                {
                    ['get'].includes(getPutPost) &&
                    <div className='w-full flex flex-nowrap justify-end text-green4 p-2.5'>
                        <p className='cursor-pointer' onClick={() => setGetPutPost('put')}>
                            Edit Role
                        </p>
                    </div>
                }
                <div className="flex flex-nowrap items-center justify-end w-full">
                    {
                        ['put'].includes(getPutPost) && !loading &&
                        <div className='mr-8 text-green3 cursor-pointer' onClick={() => setGetPutPost('get')}>
                            Cancel
                        </div>
                    }
                    {
                        getPutPost !== 'get' && roleData.role !== "" && !loading && <button className='submit-button' onClick={postRole}>
                            Save
                        </button>
                    }
                </div>
            </div>
        </div>
    );
};

export default Role;