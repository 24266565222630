import { useEffect, useState, useRef } from "react";
import GlobalSVG from '../../utils/GlobalSVG';
import AlertActivityLine from "../AlertActivityLine";
import Swal from 'sweetalert2'
import API from "../../utils/API";

const AlertActivitiesSection = (props) => {


    const [activities, setActivities] = useState(null)
    const [adding, setAdding] = useState(null)
    const [bulkUpdate, setBulkUpdate] = useState(null)
    const [bulkList, setBulkList] = useState([])
    const activitiesSection = useRef(null)
    const [selectedPoints, setSelectedPoints] = useState([])

    useEffect(() => {
        if (!props.groups || (!props.group && !props.byCircuit)) return
        let tempGroup = { activities: [] }
        if (props.byCircuit) {
            props.groups.forEach(group => {
                tempGroup.activities.push(...JSON.parse(JSON.stringify(group.activities)))
            })
        } else {
            tempGroup = JSON.parse(JSON.stringify(props.group))
        }
        for (let key in tempGroup) {
            if (typeof tempGroup[key] === 'number' && !Number.isInteger(tempGroup[key]) && key.includes('Price')) {
                tempGroup[key] = Math.round(tempGroup[key])
            }
        }
        setActivities(tempGroup.activities)

    }, [props.groups, props.group, props.byCircuit])

    useEffect(() => {
        if (adding || bulkUpdate) {
            if (activitiesSection.current) {
                activitiesSection.current.scrollTo({ top: 0, behavior: 'smooth' });
            }
        }
    }, [adding, bulkUpdate, activitiesSection])

    const bulkSave = async (activity) => {
        let tempGroups = JSON.parse(JSON.stringify(props.groups));
        await Promise.all(
            bulkList.map(async (item) => {
                await updateActivityCall({ ...activity, id: item }, true);
                tempGroups = tempGroups.map(e => {
                    if (e.id === props.group.id) {
                        let activities = e.activities.map(a => {
                            return a.id === item ? { ...a, ...activity } : a;
                        });
                        return { ...e, activities };
                    }
                    return e;
                });
            })
        );

        props.setGroups(tempGroups);
        setBulkUpdate(false);
        setBulkList([]);

        return true;
    };

    const handleSave = async (activity) => {
        if (bulkUpdate) {
            if (bulkList.length === 0) {
                Swal.fire({
                    title: 'Oops!',
                    text: 'Please select at least one Alert Activity.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                })
                return false
            } else {
                return await bulkSave(activity)
            }
        }
        if (activity.channel_name && !activity.channel_id) activity.channel_id = props.equipmentList[activity.channel_name].channel_id
        if (adding) {
            if (selectedPoints.length === 0 && !activity.channel_id && !activity.device_id && activity.type_name !== "Multi Channel Correlation") {
                Swal.fire({
                    title: 'Oops!',
                    text: 'Please select at least one monitoring point.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                })
                return false
            } else if (selectedPoints.length > 1) {
                let result = await Swal.fire({
                    title: 'Bulk Create',
                    text: 'You are about to create multiple alerts, would you like to procede?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, create all!',
                    cancelButtonText: 'No, cancel!',
                    reverseButtons: true
                })
                if (result.isConfirmed) {
                    if (validatePayload(activity)) {
                        return await createActivity(activity)
                    }
                    else return false
                } else {
                    return false
                }

            } else {
                if (validatePayload(activity)) return await createActivity(activity)
                else return false
            }
        } else {
            if (props.byCircuit) {
                let result = await Swal.fire({
                    title: 'Single Update',
                    text: 'Your changes will only apply to this circuit, would you like to procede?',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonText: 'Yes, update it!',
                    cancelButtonText: 'No, cancel!',
                    reverseButtons: true
                })
                if (result.isConfirmed) {
                    return await updateActivityCall(activity)
                } else {
                    return false
                }

            } else {
                return await updateActivityCall(activity)
            }
        }
    }


    const updateActivityCall = async (activity, bulk = false) => {
        let allowed = ["schedule_type", "frequency", "active", "months", "days", "hours", "parameters", "description"]
        let payload = JSON.parse(JSON.stringify(activity))
        
        for (let key in payload) {
            if (!allowed.includes(key) || payload[key] === null) delete payload[key]
        }
        if (payload.parameters && payload.parameters.length > 0){
            for (let item of payload.parameters) {
                delete item.id
                if (item.type === 'circuit') {
                    item.value = JSON.stringify(item.value)
                }
            }
        }
        try {
            
            await API.updateAlertActivity(payload, activity.id)
            if (!bulk) {
                let tempGroups = JSON.parse(JSON.stringify(props.groups))
                let updatedGroups = tempGroups.map(e => {
                    if (e.id === activity.alert_group_id) {
                        let activities = e.activities.map(a => {
                            if (a.id === activity.id) {
                                return { ...a, ...activity }
                            } else return a
                        })
                        return { ...e, activities: JSON.parse(JSON.stringify(activities)) }
                    }
                    else {
                        return e
                    }
                })
                props.setGroups(updatedGroups)
            }
            return true
        } catch (e) {
            console.log(e)
            Swal.fire({
                title: 'Oops!',
                text: 'An error occurred, please try again later.',
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#46775A',
            })
            return false
        }
    }

    const validatePayload = (activity) => {
        let missingVal = activity.parameters.filter(e=>!e.value || (e.type === 'circuit' && (!e.value.channel_id || !e.value.threshold)))

        if (missingVal.length) {
            Swal.fire({
                title: 'Missing Values!',
                text: 'Please complete all the fields before saving.',
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#46775A',
            })
            return false
        }
        return true
    }

    const createActivity = async (activity) => {
        let tempActivity = JSON.parse(JSON.stringify(activity))
        Object.values(tempActivity.parameters).forEach(p => {
            if (p.type === 'circuit') {
                p.value = JSON.stringify(p.value)
            }
        })
        
        let tempGroups = JSON.parse(JSON.stringify(props.groups))
        let updatedGroups
        let tempPoints = [...selectedPoints]
        if (tempPoints.length === 0 && tempActivity.channel_id) tempPoints.push(tempActivity.channel_id)
        else if(activity.type_name === "Multi Channel Correlation") tempPoints.push(-1)
        for (let channel_id of tempPoints) {
            if (props.group.channel_type === 3) tempActivity.device_id = channel_id
            else tempActivity.channel_id = channel_id

            try {
                let res = await API.createAlertActivity(tempActivity)
                updatedGroups = tempGroups.map(e => {
                    if (e.id === props.group.id) {
                        let activities = e.activities || []
                        activities.push(res.data)
                        return { ...e, activities: JSON.parse(JSON.stringify(activities)) }
                    }
                    return e
                })
                props.setGroups(updatedGroups)
            } catch (e) {
                console.log(e)
                Swal.fire({
                    title: 'Oops!',
                    text: 'An error occurred, please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                })
                return false
            }
        }
        setAdding(false)
        return true
    }

    return (
        <div>
            <div className="flex flex-nowrap justify-between items-center mt-[30px] mb-[10px]">
                <h2 className="text-xl font-semibold">Alert Settings</h2>
                <div className="flex flex-nowrap justify-end items-center">
                    {!props.byCircuit && !bulkUpdate &&
                        <div className="flex flex-nowrap justify-end items-center" onClick={() => setAdding(true)}>
                            {GlobalSVG.plus2()}
                            <p className='text-[#107048] tablet:text-[16px] mobile:text-[12px] font-medium cursor-pointer ml-2'>Add Alert</p>
                        </div>}
                    {!props.byCircuit && !adding &&
                        <div className="flex flex-nowrap justify-end items-center ml-6" onClick={() => setBulkUpdate(!bulkUpdate)}>
                            {GlobalSVG.edit("#107048")}
                            <p className='text-[#107048] tablet:text-[16px] mobile:text-[12px] font-medium cursor-pointer ml-2'>{bulkUpdate ? "Cancel" : "Bulk Update"}</p>
                        </div>}
                </div>
            </div>
            <div className="flex flex-nowrap items-center justify-between px-4 py-2 w-full text-[12px] text-[#B8BBB9] font-semibold">
                {bulkUpdate &&
                    <div className="flex flex-nowrap items-center justify-center">
                        <input type="checkbox" className="mx-2" onChange={(e) =>
                            setBulkList(e.target.checked
                                ? props.group.activities.map(e => e.id)
                                : []
                            )
                        } />
                    </div>
                }
                {props.byCircuit && <p className="flex flex-nowrap justify-start items-center w-[100px] text">Severity</p>}
                <p className="flex flex-nowrap justify-start items-center w-[90px] text">Status</p>
                {props.byCircuit && <p className="flex flex-nowrap justify-start items-center w-[120px] text">Type</p>}
                {props.byCircuit && <p className="flex flex-nowrap justify-start items-center w-[120px] text">Group</p>}
                {!props.byCircuit && <p className="flex flex-nowrap justify-start items-center w-[220px] text">Monitored Point</p>}
                <p className="flex flex-nowrap justify-start items-center w-[120px] text">Schedule Type</p>
                <p className="flex flex-nowrap justify-start items-center w-[90px] text">Frequency</p>
                <p className="flex flex-nowrap justify-start items-center w-[90px] text">Active</p>
                <p className="flex flex-nowrap justify-start items-center w-[110px] text">Last Triggered</p>
                <p className="flex flex-nowrap justify-start items-center w-[100px] text">Triggered Since</p>
                <p className="flex flex-nowrap justify-start items-center w-[75px] text"></p>
            </div>
            <div className="overflow-y-auto max-h-[250px]" ref={activitiesSection}>
                {
                    adding &&
                    <AlertActivityLine adding={adding} setAdding={setAdding} activity={{ alert_group_id: props.group.id, type_name: props.group.type_name, active: 1, frequency: 0, group_parameters: props.group.parameters ? [...props.group.parameters] : [], schedule_type: 0, status: "Set", channel_type: props.group.channel_type }} equipmentList={props.equipmentList} group={props.group} setGroup={props.setGroup} groups={props.groups} setGroups={props.setGroups} handleSave={handleSave} selectedPoints={selectedPoints} setSelectedPoints={setSelectedPoints} />
                }
                {
                    bulkUpdate &&
                    <AlertActivityLine bulkUpdate={bulkUpdate} setAdding={setAdding} activity={{ alert_group_id: props.group.id, type_name: props.group.type_name, group_parameters: props.group.parameters ? [...props.group.parameters] : [], channel_type: props.group.channel_type }} equipmentList={props.equipmentList} group={props.group} setGroup={props.setGroup} groups={props.groups} setGroups={props.setGroups} bulkList={bulkList} handleSave={handleSave} setBulkList={setBulkList} />
                }
                {
                    activities && activities.map((e, idx) => (
                        <AlertActivityLine key={idx + "activities"} activity={e} equipmentList={props.equipmentList} group={props.group} setGroup={props.setGroup} groups={props.groups} setGroups={props.setGroups} bulkList={bulkList} setBulkList={setBulkList} bulkOption={bulkUpdate} handleSave={handleSave} byCircuit={props.byCircuit} />
                    ))
                }
            </div>


        </div>
    )
}

export default AlertActivitiesSection;