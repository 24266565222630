import { useState, createContext, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router';
import './style.css';
import API from '../../utils/API';
import Swal from "sweetalert2";

export const AppContext = createContext();

export const ContextProvider = (props) => {
    const location = useLocation();
    const navigate = useNavigate();
    const [userInfo, setUserInfo] = useState(null)
    const [buildingInfo, setBuildingInfo] = useState(null)
    const [building, setBuilding] = useState(null)
    const [fixed30DayData, setFixed30DayData] = useState(null)
    const [openSidebar, setOpenSidebar] = useState(false)
    const [mobile, setMobile] = useState(window.innerWidth <= 640);
    const [laptop, setLaptop] = useState(window.innerWidth <= 1225);
    const [nodeSelected, setNodeSelected] = useState({})
    const [gwUser, setGwUser] = useState(true);
    const [versionInfo, setVersionInfo] = useState(null)
    const [faq, setFaq] = useState(null)
    const [forceRefresh, setForceRefresh] = useState(false)
    const maintenance = false
    const outsideLogin = ['/recover', '/login', '/passchange']

    useEffect(() => {
        localStorage.removeItem('gw_usr_dt_ls');
        sessionStorage.setItem("lastLocation", ['/login', '/map', '/'].includes(location.pathname) ? (sessionStorage.getItem("lastLocation") ? sessionStorage.getItem("lastLocation") : "") :  location.pathname + location.search)        
        getUserInfo()
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        const interval = setInterval(() => {
            if (localStorage.getItem('is_logged_in') !== 'true') {
                sessionStorage.setItem("lastLocation", ['/login', '/map', '/'].includes( location.pathname) ? (sessionStorage.getItem("lastLocation") ? sessionStorage.getItem("lastLocation") : "") :  location.pathname + location.search)
                setUserInfo(false)
                if (outsideLogin.includes(location.pathname) || location.pathname.includes('/public')) return
                navigate('/login', { replace: false })
            }
        }, 5000);
        return () => clearInterval(interval);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [navigate, location.pathname]);

    const handleResize = () => {
        setMobile(window.innerWidth <= 640);
        setLaptop(window.innerWidth <= 1225);
    };

    useEffect(() => {

        if (maintenance) {
            navigate("/unavailable")
            return
        }

        if (userInfo && (userInfo.client_id !== 'Greenwave')) {
            setGwUser(false)
        }
        if ([null, '', 'all'].includes(building) || !userInfo) return
        const cancelToken = API.cancelToken();

        API.getBuilding(cancelToken, building).then(res => {
            setBuildingInfo(res.data)
        }).catch(err => {
            console.log(err)
            if (err.message === 'cancelling') return
            Swal.fire({
                title: 'Error.',
                text: 'Please try again later.',
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#46775A',
            });

        });
        return () => {
            API.cancel(cancelToken);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [building, userInfo, navigate])

    useEffect(() => {
        if (maintenance) {
            navigate("/unavailable")
            return
        }

        if (location.pathname === '/') {
            navigate("/map")
        }
        const onlyGw = []

        if (outsideLogin.includes(location.pathname) || location.pathname.includes('/public')) return

        if (userInfo === false) {
            navigate("/login")
            return
        }

        if (userInfo && onlyGw.includes(location.pathname) && userInfo.client_id !== 'Greenwave') {
            navigate("/")
            return
        }

        const cancelToken = API.cancelToken();

        API.getVersion(cancelToken).then(res => {
            setVersionInfo(res.data)
        }).catch(err => {
            console.log(err)
            if (err.message === 'cancelling') return
            Swal.fire({
                title: 'Error.',
                text: 'Please try again later.',
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#46775A',
            });

        });

        API.getFaq(cancelToken).then(res => {
            setFaq(res.data)
        }).catch(err => {
            console.log(err)
            if (err.message === 'cancelling') return
            Swal.fire({
                title: 'Error.',
                text: 'Please try again later.',
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#46775A',
            });

        });
        return () => {
            API.cancel(cancelToken);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [userInfo, location, navigate, forceRefresh])

    const logout = () => {
        localStorage.setItem('is_logged_in', 'false')
        API.logOut().then(res => {
            setUserInfo(false)
            navigate("/login")
        }).catch(err => {
            if (err.message === 'cancelling') return
            else {
                console.log(err)
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
            }
        });
    }

    const login = async () => {
        getUserInfo()
        localStorage.setItem('is_logged_in', 'true')
        navigate(sessionStorage.getItem("lastLocation"))
    }

    const getUserInfo = async () => {
        const cancelToken = API.cancelToken();
        try {
            let tempUserInfo = await API.getUser(cancelToken)
            setUserInfo(tempUserInfo.data)
        } catch (err) {
            if (err.message === 'cancelling') return
            if (err && err.response && err.response.status === 401) {
                if (outsideLogin.includes(location.pathname) || location.pathname.includes('/public')) return
                navigate('/login', { replace: false })
            }
            else {
                console.log(err)
                Swal.fire({
                    title: 'Error.',
                    text: 'Please try again later.',
                    icon: 'warning',
                    confirmButtonText: 'Ok',
                    confirmButtonColor: '#46775A',
                });
            }
        }
    }

    const resetBuilding = () => {
        setBuilding(null)
        setBuildingInfo(null)
        setFixed30DayData(null)
    }

    return (
        <AppContext.Provider value={
            {
                getUserInfo: userInfo,
                login: login,
                logout: logout,
                setBuildingInfo: setBuildingInfo,
                setBuilding: setBuilding,
                isGwUser: gwUser,
                getBuilding: building,
                getBuildingInfo: buildingInfo,
                getFixed30DayData: fixed30DayData,
                setFixed30DayData: setFixed30DayData,
                resetBuilding: resetBuilding,
                getSidebar: openSidebar,
                setSidebar: setOpenSidebar,
                getMobile: mobile,
                getLaptop: laptop,
                getFaq: faq,
                versionInfo: versionInfo,
                nodeSelected: nodeSelected,
                setNodeSelected: setNodeSelected,
                setForceRefresh: setForceRefresh,
            }
        }>
            {props && props.children}
        </AppContext.Provider>
    )
}

export default ContextProvider;