import React from 'react';
import { useState, useEffect, useContext, useMemo } from 'react';
import { AppContext } from "../../utils/ContextProvider";
import Loader from "../Loader";
import { Link, useNavigate, useLocation } from 'react-router-dom';
import './style.css'
import API from '../../utils/API';
import Swal from 'sweetalert2';
import GlobalSVG from '../../utils/GlobalSVG';
import FilterableSelect from '../../components/FilterableSelect';

const Devices = () => {
    const [data, setData] = useState([])
    const [options, setOptions] = useState([])
    const [filteredData, setFilteredData] = useState([])
    const [filters, setFilters] = useState([])
    const [sorting, setSorting] = useState(true)
    const [showFilters, setShowFilters] = useState(false)
    const context = useContext(AppContext)
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = useMemo(() => new URLSearchParams(location.search), [location.search]);

    useEffect(() => {
        if (!context.getUserInfo) return
        let access = ["access_buildings_and_operators_list", "create_buildings_and_operators", "update_buildings_and_operators"]
        if (!access.some(item => context.getUserInfo[item] === true)) {
            navigate('/settings/buildings')
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [context.getUserInfo])

    useEffect(() => {
        let tempFilters = Object.fromEntries(new URLSearchParams(location.search).entries());
        sessionStorage.setItem("lastLocation", `/settings/devices?${new URLSearchParams(tempFilters).toString()}`)
        setFilters(tempFilters)

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [queryParams, location])

    const handleFilter = ({ key, value }) => {
        let tempFilters = Object.fromEntries(new URLSearchParams(location.search).entries());
        if (['Active', 'Building', 'Client', 'Device Name', 'Device ID'].includes(value)) value = 'null'
        tempFilters[key] = value
        navigate(`/settings/devices?${new URLSearchParams(tempFilters).toString()}`);
    }

    const removeFilter = (name) => {
        let tempFilters = Object.fromEntries(new URLSearchParams(location.search).entries());
        delete tempFilters[name]
        setFilters(({ [name]: _, ...rest }) => rest)
        navigate(`/settings/devices?${new URLSearchParams(tempFilters).toString()}`);
    }


    useEffect(() => {
        if (!context.getUserInfo) return
        const cancelToken = API.cancelToken();

        API.getDevices(cancelToken).then(res => {
            let buildingOptions = [...new Set(res.data.map(e => e.building))]
            let clientOptions = [...new Set(res.data.map(e => e.client_name))]
            let devicenameOptions = [...new Set(res.data.map(e => e.device_name))]
            let deviceIdOptions = [...new Set(res.data.map(e => e.device_id))]
            setOptions({ buildings: buildingOptions, clients: clientOptions, deviceIds: deviceIdOptions, deviceNames: devicenameOptions })
            setData(res.data)
            return
        }).catch(err => {
            console.log(err)
            if (err.message === 'cancelling') return
            Swal.fire({
                title: 'Error.',
                text: 'Please try again later.',
                icon: 'warning',
                confirmButtonText: 'Ok',
                confirmButtonColor: '#46775A',
            });

        })
        return () => {
            API.cancel(cancelToken);
        }
    }, [context.getUserInfo])

    useEffect(() => {
        if (data.length === 0) return
        let temp = JSON.parse(JSON.stringify(data))
        for (let field in filters) {
            if (filters[field] === "null") continue
            if (field === 'active') {
                temp = temp.filter(line => parseInt(line[field]) === parseInt(filters[field]))
            } else {
                temp = temp.filter(line => line[field] === filters[field])
            }
        }

        for (let field in sorting) {
            temp.sort((a, b) => {
                return sorting[field]
                    ? a[field].localeCompare(b[field])
                    : b[field].localeCompare(a[field])

            });
        }

        setFilteredData(temp)
    }, [data, filters, sorting])

    const handleSort = (field) => {
        setSorting(e => e.hasOwnProperty(field) ? { [field]: !e[field] } : { [field]: true })
    }

    return (
        <div className=''>
            <div className="relative card w-full py-2 px-4 background-white flex flex-nowrap justify-end items-center">
                <div className={`relative flex laptop:justify-end laptop:items-end tablet:items-end flex-col multi-select m-3`}>
                    <div className="relative flex items-center justify-start text-sm text-normal text-[grey] cursor-pointer">
                        <div onClick={() => setShowFilters(!showFilters)} className="w-full mx-3 flex flex-nowrap items-center justify-center">
                            {GlobalSVG.filter()}
                            <p className='text-[#107048] tablet:text-[16px] mobile:text-[12px] font-medium cursor-pointer ml-2 mr-6'>Filter</p>
                        </div>
                        {
                            showFilters &&
                            <div className={`absolute top-[120%] right-[30px] w-[130px] h-[150px] overflow-auto flex flex-col p-1 whitespace-nowrap shadow-md border-[#C1C1C1] z-10 bg-[#FFF]`}>
                                <div className="flex flex-row mx-2 py-1 border-b border-[#B8BBB9] text-[black] font-semibold" onClick={() => handleFilter({ key: "active", value: null })}>
                                    Active
                                </div>
                                <div className="flex flex-row mx-2 py-1 border-b border-[#B8BBB9] text-[black] font-semibold" onClick={() => handleFilter({ key: "client_name", value: null })}>
                                    Client
                                </div>
                                <div className="flex flex-row mx-2 py-1 border-b border-[#B8BBB9] text-[black] font-semibold" onClick={() => handleFilter({ key: "building", value: null })}>
                                    Building
                                </div>
                                <div className="flex flex-row mx-2 py-1 border-b border-[#B8BBB9] text-[black] font-semibold" onClick={() => handleFilter({ key: "device_name", value: null })}>
                                    Device Name
                                </div>
                                <div className="flex flex-row mx-2 py-1 border-b border-[#B8BBB9] text-[black] font-semibold" onClick={() => handleFilter({ key: "device_id", value: null })}>
                                    Device ID
                                </div>
                            </div>
                        }
                    </div>
                </div>
            </div>
            {Object.keys(filters).length > 0 &&
                <div className="flex flex-nowrap tablet:m-8 mobile:m-3">
                    {
                        filters.hasOwnProperty('active') &&
                        <div className="flex flex-nowrap justify-center items-center rounded-md border border-[#46775A] mr-4 pl-2">
                            <select className='border-none text-[12px] font-semibold h-[33px]' name="active" onChange={(event) => handleFilter({ key: "active", value: event.target.value })}>
                                <option value={null}>All</option>
                                <option value="0">Inactive</option>
                                <option value="1">Active</option>
                            </select>
                            <div className='w-[5px]'></div>
                            <div className="flex flex-nowrap justify-center items-center h-full rounded-r-sm w-[30px] h-full bg-[#46775A] cursor-pointer"
                                onClick={() => removeFilter("active")}>
                                {GlobalSVG.close('white')}
                            </div>
                        </div>
                    }
                    {
                        filters.hasOwnProperty('client_name') &&
                        <div className="flex flex-nowrap justify-center items-center w-[300px] mr-4">
                            <FilterableSelect
                                options={options.clients}
                                disabled={false}
                                placeholder="Client"
                                name='client_name'
                                onChange={(event) => handleFilter({ key: "client_name", value: event.target.value })} />
                            <div className="flex flex-nowrap justify-center items-center h-full rounded-r-sm w-[30px] h-full bg-[#46775A] cursor-pointer ml-[-2px]"
                                onClick={() => removeFilter("client_name")}>
                                {GlobalSVG.close('white')}
                            </div>
                        </div>
                    }
                    {
                        filters.hasOwnProperty('building') &&
                        <div className="flex flex-nowrap justify-center items-center w-[300px] mr-4">
                            <FilterableSelect
                                options={options.buildings}
                                disabled={false}
                                placeholder="Building"
                                name='building'
                                onChange={(event) => handleFilter({ key: "building", value: event.target.value })} />
                            <div className="flex flex-nowrap justify-center items-center h-full rounded-r-sm w-[30px] h-full bg-[#46775A] cursor-pointer ml-[-2px]"
                                onClick={() => removeFilter("building")}>
                                {GlobalSVG.close('white')}
                            </div>
                        </div>
                    }
                    {
                        filters.hasOwnProperty('device_name') &&
                        <div className="flex flex-nowrap justify-center items-center w-[300px] mr-4">
                            <FilterableSelect
                                options={options.deviceNames}
                                disabled={false}
                                placeholder="Device Name"
                                name='device_name'
                                onChange={(event) => handleFilter({ key: "device_name", value: event.target.value })} />
                            <div className="flex flex-nowrap justify-center items-center h-full rounded-r-sm w-[30px] h-full bg-[#46775A] cursor-pointer ml-[-2px]"
                                onClick={() => removeFilter("device_name")}>
                                {GlobalSVG.close('white')}
                            </div>
                        </div>
                    }
                    {
                        filters.hasOwnProperty('device_id') &&
                        <div className="flex flex-nowrap justify-center items-center w-[300px] mr-4">
                             <FilterableSelect
                                options={options.deviceIds}
                                disabled={false}
                                placeholder="Device ID"
                                name='device_id'
                                onChange={(event) => handleFilter({ key: "device_id", value: event.target.value })} />
                            <div className="flex flex-nowrap justify-center items-center h-full rounded-r-sm w-[30px] h-full bg-[#46775A] cursor-pointer ml-[-2px]"
                                onClick={() => removeFilter("device_id")}>
                                {GlobalSVG.close('white')}
                            </div>
                        </div>
                    }
                </div>
            }
            <div className='card tablet:m-8 mobile:m-3 tablet:p-8 mobile:p-3 min-h-[90vh]'>
                <div className='overflow-auto h-[70vh]'>
                    <div className='flex flex-nowrap justify-between w-full min-w-[1156px]'>
                        <div className='flex flex-nowrap justify-start items-center w-[200px] text-[12px] text-[#B8BBB9] font-semibold cursor-pointer justify-center'
                            onClick={() => handleSort('client_name')}>
                            <span className={`mr-2 ${sorting.hasOwnProperty("client_name") ? 'text-[green]' : ''}`}>Client</span>
                            {sorting.hasOwnProperty("client_name") && sorting.client_name === true ?
                                GlobalSVG.sortArrowAsc('green') :
                                sorting.hasOwnProperty("client_name") && sorting.client_name === false ?
                                    GlobalSVG.sortArrowDesc('green') :
                                    GlobalSVG.sortArrowAsc('#B8BBB9')}
                        </div>
                        <div className='flex flex-nowrap justify-start items-center w-[200px] text-[12px] text-[#B8BBB9] font-semibold cursor-pointer justify-center'
                            onClick={() => handleSort('building')}>
                            <span className={`mr-2 ${sorting.hasOwnProperty("status") ? 'text-[green]' : ''}`}>Building</span>
                            {sorting.hasOwnProperty("building") && sorting.building === true ?
                                GlobalSVG.sortArrowAsc('green') :
                                sorting.hasOwnProperty("building") && sorting.building === false ?
                                    GlobalSVG.sortArrowDesc('green') :
                                    GlobalSVG.sortArrowAsc('#B8BBB9')}
                        </div>
                        <div className='flex flex-nowrap justify-start items-center w-[200px] text-[12px] text-[#B8BBB9] font-semibold cursor-pointer justify-center'
                            onClick={() => handleSort('device_name')}>
                            <span className={`mr-2 ${sorting.hasOwnProperty("device_name") ? 'text-[green]' : ''}`}>Device Name</span>
                            {sorting.hasOwnProperty("device_name") && sorting.device_name === true ?
                                GlobalSVG.sortArrowAsc('green') :
                                sorting.hasOwnProperty("device_name") && sorting.device_name === false ?
                                    GlobalSVG.sortArrowDesc('green') :
                                    GlobalSVG.sortArrowAsc('#B8BBB9')}
                        </div>
                        <div className='flex flex-nowrap justify-start items-center w-[200px] text-[12px] text-[#B8BBB9] font-semibold cursor-pointer justify-center'
                            onClick={() => handleSort('device_id')}>
                            <span className={`mr-2 ${sorting.hasOwnProperty("device_id") ? 'text-[green]' : ''}`}>Device ID</span>
                            {sorting.hasOwnProperty("device_id") && sorting.device_id === true ?
                                GlobalSVG.sortArrowAsc('green') :
                                sorting.hasOwnProperty("device_id") && sorting.device_id === false ?
                                    GlobalSVG.sortArrowDesc('green') :
                                    GlobalSVG.sortArrowAsc('#B8BBB9')}
                        </div>
                    </div>
                    <div className="h-[80%] overflow-auto">
                        {filteredData.length ?
                            filteredData.map((e, ix) => (
                                <Link key={ix + "tableitem"} className='w-full' to={`/device?device=${e.device_id}&tab=device`}>
                                    <div key={'activitykey' + ix} className='flex flex-nowrap justify-between items-center p-4 my-2 w-full min-w-[1156px] bg-[#f7f7f7] rounded-md'>
                                        <div className={`w-[200px] text-[14px] text-center font-medium`}>{e.client_name ? e.client_name : " - "}</div>
                                        <div className={`w-[200px] text-[14px] text-center font-medium`}>{e.building ? e.building : " - "}</div>
                                        <div className={`w-[200px] text-[14px] text-center font-medium`}>{e.device_name ? e.device_name : " - "}</div>
                                        <div className={`w-[200px] text-[14px] text-center font-medium`}>{e.device_id ? e.device_id : " - "}</div>
                                    </div>
                                </Link>
                            ))
                            :
                            <Loader />
                        }
                    </div>
                    <Link to={`/device/create`} className='w-full border-light-green block mt-8 text-sm text-green4 font-medium'>
                        <span className='text-lg mr-2'>+</span>
                        Add new device
                    </Link>
                </div>
            </div>
        </div>
    );
};

export default Devices;